import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Config } from '../Config'

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: fetchBaseQuery(
    { 
        baseUrl: Config.API_URL_LOCAL, 
        prepareHeaders: (headers, { getState }) => { 
        //  console.log("getState()",getState())  
          if(getState().userData.user_data)
          {
          const user = getState().userData.user_data.token
       
//console.log("userToken",user)
headers.set('Authorization', `Bearer ${user}`)
          }
       
            return headers
          }
 }),
  endpoints: () => ({}),
})
