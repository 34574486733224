export default build =>
build.query({
    tagTypes: ['packagesById'],
    keepUnusedDataFor: 1,
    query(payload) {
        console.log("payload",payload)            
        return {
            url: `/course/getcoursebyid?course_id=${payload.course_id}`,
            method: 'GET',
            //body: payload,
        };
       
    },
   providesTags: ['packagesById']
})
