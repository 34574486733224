export default build =>
    build.mutation({
        query(payload) {
        console.log("In upload Intro Video",payload)
            return {
                url: '/course/uploadintrovideo',
                method: 'POST',
                body: payload,
               
            };
            
        },
        invalidatesTags: ['courseById'],
    })

