
export default build =>
    build.query({
        tagTypes: ['allUploadedShorts'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/youtube/getShorts?pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}`,                         
                method: 'GET',
              
            };
           
        },
       providesTags: ['allUploadedShorts']
    })
