export default build =>
    build.query({
        tagTypes: ['allRecordedVideos'],
        keepUnusedDataFor: 1,
        query() {
            console.log("In get all recorded videos")            
            return {
                url: '/course/getAllRecordedVideos',
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allRecordedVideos']
    })


