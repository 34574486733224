import logo from './logo.svg';
import './App.css';
import AppRoutes from './AppRoutes';
import { store } from './Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} 
      persistor={persistor}>
    <div className="App">
     <AppRoutes/>
    </div>
    </PersistGate>
    </Provider>
  );
}

export default App;
