import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";
import { useCreateNotesMutation, useDeleteNotesMutation, useGetAllNotesQuery } from '../Services/modules/notes'
import { Config } from '../Config'
function Notes() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
const{data:notesData=[],isSuccess:isNotesSuccess,isLoading:isNotesLoading,error:isnOtesError}=useGetAllNotesQuery()
  const [CreateNotes, { data, isSuccess, isLoading, isError, error }] = useCreateNotesMutation();
  const [DeleteNotes, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError}] = useDeleteNotesMutation();

  const { 
    data: courses= [],  
    isLoading:loading=true,
    isSuccess:getAllCoursesSuccess=false
   } = useGetAllCoursesQuery();
   const columns = [ 
   
    { 
    key: "notes_caption", 
    title: "Caption", 
    dataIndex: "notes_caption", 
    }, 
    { 
      key: "notes_file", 
      title: "File", 
      render: (_, record) => (
            <Space size="middle">    
            <a href={`${Config.API_FILE}/notes/${record.notes_file}`} target='_blank' style={{color:"green"}}>
              <AttachmentIcon style={{fontSize:'medium'}}/> 
              </a>                
            </Space>
          ),
      }, 
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>deleteNotes(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
         
        </Space>
      ),
    },
    
    ] 
    const [notesCaption, setNotesCaption] = useState('');
  const [file, setFile] = useState([]);
    const[isupdateRecord,setUpdateRecord]=useState(false);
    const[allNotes,setAllNotes]=useState([])
    useEffect(()=>{
      console.log("notes data",notesData)
    if(isNotesSuccess)
    {
    
      setAllNotes(notesData.data)
    }
    },[notesData])
useEffect(()=>{
  console.log("notes data",notesData)
if(isSuccess)
{
  toast.success("Notes created successfully")
  setAllNotes(notesData.data)
}
},[data])
useEffect(()=>{
  console.log("notes data",notesData)
if(deleteSuccess)
{
  toast.success("Notes Deleted successfully")
  setAllNotes(notesData.data)
}
},[deleteData])

const deleteNotes=(record)=>{
console.log("Notes id",record._id)
DeleteNotes({notes_id:record._id})
}
    const handleCaptionChange = (event) => {
        setNotesCaption(event.target.value);
      };
    
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Check if the selected file is a PDF
        if (selectedFile && selectedFile.type === 'application/pdf') {
          setFile(selectedFile);
        } else {
          alert('Please select a PDF file.');
        }
      };
    
    function onSubmit (){    
        console.log("notes file",file)
        const form = new FormData(); 

        form.append('notes_caption',notesCaption);    
        form.append('notes_file',file); 

            CreateNotes(form)
         
       
        
     // }  
    
     }
    const notesScreen=()=>{
        return(
          <>
           <ToastContainer />
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div>         
          <TextBox             
             label={"Caption"}
             id={"notesCaption"}
             name={"notesCaption"}
             autoFocus={true}                         
            value={notesCaption}  
            isSelect={false}      
            onChange={handleCaptionChange}
            />        
        </div>
        <div>
         
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />
        </div>
       
            <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allNotes}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {notesScreen()}
   </AdminTemplate>
  )
}

export default Notes