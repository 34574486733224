import React from 'react'
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';


function SubmitButton({onClick,caption,button_color,isLoading,disabled}) {
  //alert(isLoading)
  return (
    <Button
                onClick={onClick}
                fullWidth
               disabled={!isLoading? disabled:isLoading}

//                disabled={isLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2,backgroundColor:button_color }}

              >
             
               {
               !isLoading?caption:
              <CircularProgress color="inherit" size="1rem" /> 

               }
              </Button>
  )
}

export default SubmitButton