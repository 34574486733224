import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import CoursePackageForm from '../Components/CoursePackageForm'
import AdminTemplate from '../Components/Template/AdminTemplate'

function CoursePackage({course_id}) {
    const coursePackageScreen=()=>{
return(
    <div>
      
        <Grid 
            container 
            spacing={3} 
            mb={5}                
            >
                 <Grid item xs={12} md={4} lg={5}>
                   
                
                 <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
<CoursePackageForm/> 
                </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={5}>
                   
                
                   <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: "auto",
                    }}
                  >
  {/* <CoursePackageForm/>  */}
                  </Paper>
                   </Grid>
               
            </Grid>
    </div>
)
    }
  return (
   <AdminTemplate>
    {coursePackageScreen()}
   </AdminTemplate>
  )
}

export default CoursePackage