import { api } from '../../api'
import createHomework from './createHomework'
import createQuotes from './createQuotes'
import createWord from './createWord'
import createYoutubeDetails from './createYoutubeDetails'
import deleteHomework from './deleteHomework'
import deleteQuotes from './deleteQuotes'
import deleteWord from './deleteWord'
import deleteYoutubeDetails from './deleteYoutubeDetails'
import getAllHomework from './getAllHomework'
import getAllWords from './getAllWords'
import getQuotes from './getQuotes'
import getYoutubeDetails from './getYoutubeDetails'
import updateHomework from './updateHomework'
import updateQuotes from './updateQuotes'
import updateWord from './updateWord'


export const otherInfoApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllQuotes: getQuotes(build),         
        GetAllYoutubeDetails:getYoutubeDetails(build),
        CreateQuote:createQuotes(build),
        CreateYoutubeDetails:createYoutubeDetails(build),
        UpdateQuote:updateQuotes(build),
        DeleteQuote:deleteQuotes(build),
        DeleteYoutubeDetails:deleteYoutubeDetails(build),
        CreateWord:createWord(build),
        UpdateWord:updateWord(build),
        GetAllWords:getAllWords(build),
        DeleteWord:deleteWord(build),
        CreateHomework:createHomework(build),
        updateHomework:updateHomework(build),
        DeleteHomework:deleteHomework(build),
        GetAllHomeWork:getAllHomework(build)

    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useGetAllQuotesQuery, 
    useGetAllYoutubeDetailsQuery,
    useCreateYoutubeDetailsMutation,
    useCreateQuoteMutation,
    useUpdateQuoteMutation,
    useDeleteQuoteMutation,
    useDeleteYoutubeDetailsMutation,
    useCreateWordMutation,
    useGetAllWordsQuery,
    useUpdateWordMutation,
    useDeleteWordMutation,
    useCreateHomeworkMutation,
    useUpdateHomeworkMutation,
    useDeleteHomeworkMutation,
    useGetAllHomeWorkQuery
    } = 
    otherInfoApi
