import { api } from '../../api'

import getAllPodcasts from './getAllPodcasts'
import addPodcast from './addPodcast'
import deletePodcast from './deletePodcast'
import uploadPodcast from './uploadPodcast'
import getAllUploadedPodcast from './getAllUploadedPodcast'
import deletePodcastMobile from './deletePodcastMobile'

export const podcastApi = api.injectEndpoints({
    endpoints: build => ({
        AddPodcast: addPodcast(build),         
        GetAllPodcasts:getAllPodcasts(build),
        GetAllUploadedPodcasts:getAllUploadedPodcast(build),
        DeletePodcast:deletePodcast(build),
        DeletePodcastMobile:deletePodcastMobile(build),
        UploadPodcast:uploadPodcast(build)
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddPodcastMutation, 
    useGetAllPodcastsQuery,
    useGetAllUploadedPodcastsQuery,
    useDeletePodcastMutation,
    useUploadPodcastMutation,
    useDeletePodcastMobileMutation
} = podcastApi
