import React from 'react'
import { Select, Input, Table, Button, Icon,Space } from "antd";
function DataTable({columns,Data,totalCount,handleChange}) {
    console.log("Data in dataTable",Data)
  return (
    <div>
         <Table
                columns={columns}
                dataSource={Data}
                className="text-lg"
                scroll={{ y: 550, x: 600 }}
                onChange={handleChange} // Handle pagination changes
                pagination={{
                    total: totalCount,
                    pageSizeOptions: ['10', '20', '50'], // Customize page size options as needed
                    showSizeChanger: true, // Show page size changer
                    showQuickJumper: true, // Show quick jumper
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`, // Customize total count message
                }}
            />
    </div>
  )
}

export default DataTable