import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";
import { useCreateNotesMutation, useDeleteNotesMutation, useGetAllNotesQuery } from '../Services/modules/notes'
import { Config } from '../Config'
import { useDeletePodcastMobileMutation, useGetAllUploadedPodcastsQuery, useUploadPodcastMutation } from '../Services/modules/podcast'
function PodcastMobile() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);

 const[podcastId,setPodcastId]=useState("")
 const[showDialogDelete,setShowDialogDelete]=useState(false)

  const [UploadPodcast, { data, isSuccess, isLoading, isError, error }] = useUploadPodcastMutation();
  const [DeletePodcastMobile, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError}] = useDeletePodcastMobileMutation();
  const[totalCount,setTotalCount]=useState(0)
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10 }); // Initial pagination info

  const { 
    data: uploadedPodcastsData= [],  
    isLoading:loading=true,
    isSuccess:getUploadedPodcastsSuccess=false
   } = useGetAllUploadedPodcastsQuery({
    pageIndex: pageInfo.current - 1,
    pageSize: pageInfo.pageSize,
    type:'podcast'
  });
   const columns = [ 
   
    { 
    key: "title", 
    title: "Caption", 
    dataIndex: "title", 
    }, 
    { 
      key: "url", 
      title: "Podcast", 
      render: (_, record) => (
            <Space size="middle">   
            <audio
        controls  // This attribute adds built-in controls for play/pause, volume, etc.
        src={`${record.url}`} // Specify the path to your audio file here
        onPlay={() => setIsPlaying(true)}  // Callback when audio starts playing
        onPause={() => setIsPlaying(false)} // Callback when audio is paused
        onEnded={() => setIsPlaying(false)} // Callback when audio playback ends
      />                             
            </Space>
          ),
      }, 
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>openDeleteAlertDialog(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
         
        </Space>
      ),
    },
    
    ] 
    const [podcastCaption, setPodcastCaption] = useState('');
    const [type, setType] = useState('podcast');
   
  const [file, setFile] = useState([]);
    const[isupdateRecord,setUpdateRecord]=useState(false);
    const[allPodcasts,setAllPodcasts]=useState([])
    const handlePageChange = (pagination) => {
      setPageInfo({
          current: pagination.current,
          pageSize: pagination.pageSize,
      });
  };
   
    useEffect(()=>{
      console.log("uploaded podcast data",uploadedPodcastsData)
    if(getUploadedPodcastsSuccess)
    {
      setTotalCount(uploadedPodcastsData.total)
        setAllPodcasts(uploadedPodcastsData?.data)
    }
    },[uploadedPodcastsData])
useEffect(()=>{
 // console.log("notes data",notesData)
if(isSuccess)
{
 
  toast.success("Podcast uploaded successfully")
  //setAllNotes(notesData.data)
}
},[data])
useEffect(()=>{
 // console.log("notes data",notesData)
if(deleteSuccess)
{
  toast.success("Podcast Deleted successfully")
  setShowDialogDelete(false)
  setPodcastId("")


 // setAllNotes(notesData.data)
}
},[deleteData])

const openDeleteAlertDialog=(record)=>{
  console.log("podcassttt record",record)
  setPodcastId(record.id)
  setShowDialogDelete(true)
     }

const deletePodcast=()=>{
console.log("podcasttt iddd for delete",podcastId)
DeletePodcastMobile({podcastId})
}
const closeDialog=()=>{
  
  setShowDialogDelete(false)

 }
    const handleCaptionChange = (event) => {
        setPodcastCaption(event.target.value);
      };
    
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Check if the selected file is an MP3
        if (selectedFile && selectedFile.type === 'audio/mpeg') {
          setFile(selectedFile);
        } else {
          alert('Please select an MP3 file.');
        }
      };
    
    function onSubmit (){    
        console.log("notes file",file)
        const form = new FormData(); 

        form.append('podcast_caption',podcastCaption);    
        form.append('podcast_file',file); 
        form.append('type',type)
        UploadPodcast(form)
      
     }
     const togglePlay = () => {
        setIsPlaying(!isPlaying);
      };
    const PodcastMobileScreen=()=>{
        return(
          <>
            <Dialog
        open={showDialogDelete}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button onClick={deletePodcast} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           <ToastContainer />
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div>         
          <TextBox             
             label={"Podcast Caption"}
             id={"podcastCaption"}
             name={"podcastCaption"}
             autoFocus={true}                         
            value={podcastCaption}  
            isSelect={false}      
            onChange={handleCaptionChange}
            />        
        </div>
        <div>
         
          <input
            type="file"
            accept="audio/*"
            onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />
        </div>
      
       
            <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allPodcasts}
                  totalCount={totalCount}
                  handleChange={handlePageChange}
                  />
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {PodcastMobileScreen()}
   </AdminTemplate>
  )
}

export default PodcastMobile