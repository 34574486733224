
export default build =>
    build.query({
        tagTypes: ['allPodcasts'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
              

                url: `/podcast/getPodcasts?pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}&type=${payload.type}`,               
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allUploadedPodcasts']
    })
