import { api } from '../../api'
import createExistingStudent from './createExistingStudent'

import createStudent from './createStudent'
import deleteEnrolledStudent from './deleteEnrolledStudent'
import deleteStudent from './deleteStudent'
import getAllEnrolledStudents from './getAllEnrolledStudents'
import getAllStudents from './getAllStudents'
import updateExistingStudent from './updateExistingStudent'
import updateStudent from './updateStudent'

export const liveCoursesApi = api.injectEndpoints({
    endpoints: build => ({
        CreateStudent: createStudent(build) , 
        GetAllStudents:getAllStudents(build) ,
        UpdateStudent:updateStudent(build),
        CreateExistingStudent:createExistingStudent(build),
        UpdateExistingStudent:updateExistingStudent(build),
        GetAllEnrolledStudents:getAllEnrolledStudents(build),
        DeleteEnrolledStudent:deleteEnrolledStudent(build),
        DeleteStudent:deleteStudent(build)
    }),
    overrideExisting: true,
})
export const {
   
      useCreateStudentMutation,
      useGetAllStudentsQuery,
      useUpdateStudentMutation,
      useCreateExistingStudentMutation,
      useGetAllEnrolledStudentsQuery,
      useUpdateExistingStudentMutation,
      useDeleteEnrolledStudentMutation,
      useDeleteStudentMutation
     
     } = liveCoursesApi
