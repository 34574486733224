import React, { useEffect, useState } from 'react'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useCreateYoutubeDetailsMutation, useDeleteYoutubeDetailsMutation, useGetAllYoutubeDetailsQuery } from '../Services/modules/otherinfo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Container, Grid, Paper } from '@mui/material';
import TextBox from '../Components/InputFields/TextBox';
import SubmitButton from '../Components/InputFields/SubmitButton';
import { Button, Space } from 'antd';

function OtherInfoYoutube() {
  const{data,isSuccess,isLoading,error}=useGetAllYoutubeDetailsQuery()
  const [CreateYoutubeDetails, { data:createData, isSuccess:createSuccess, isLoading:createLoading, isError:createError }] = useCreateYoutubeDetailsMutation();
  const [DeleteYoutubeDetails, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteYoutubeDetailsMutation();
  const [youtubeDataId,setYoutubeDataId]=useState()
  const[isDeleteAlert,setDeleteAlert]=useState(false)
  const[youtube_subscribers,setYoutubeSucscribers]=useState("")
  const[youtube_views,setYoutubeViews]=useState("")
  const[total_Students,setTotalStudents]=useState("")
  const[youtubeData,setYoutubeData]=useState([])

  const columns = [ 
     
    { 
    key: "youtube_subscribers", 
    title: "Youtube Subscribers", 
    dataIndex: "youtube_subscribers", 
    }, 
    { 
      key: "youtube_views", 
      title: "Youtube Views", 
      dataIndex: "youtube_views", 
      }, 
      { 
        key: "total_Students", 
        title: "total Students", 
        dataIndex: "total_Students", 
        }, 
  
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>deleteAlert(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>  
       
        </Space>
      ),
    },
    
    ] 
  useEffect(()=>{
    if(isSuccess)
    {
      console.log("youtube data",data)
      if(data.status==false)
      {
       
setYoutubeData([])

      }
setYoutubeData(data.data)

    }
  },[data])
  useEffect(()=>{
    if(createSuccess)
    {
      toast.success(createData.message)
    }
    else
    {
      toast.error("Something went wrong")
    }
  },[createData])
  useEffect(()=>{
    if(deleteSuccess)
    {
      toast.success(deleteData.message)
      setDeleteAlert(false)
    }
    else
    {
      toast.error("Something went wrong")
      setDeleteAlert(false)

    }
  },[deleteData])
     
const deleteAlert=(record)=>{
  setYoutubeDataId(record._id)
  setDeleteAlert(true)
  }
    const otherInfoYoutubeScreen=()=>{
 
const deleteRecord=()=>{
  DeleteYoutubeDetails({youtubeDataId:youtubeDataId})
}
const onSubmit=()=>{
 
  CreateYoutubeDetails({
          youtube_subscribers:youtube_subscribers,
          youtube_views:youtube_views,
          total_Students:total_Students
        })
    
}
        return(
            <>
            <Container alignItems="center" sx={{mt:5}}>

      <ToastContainer />
      <Dialog
        open={isDeleteAlert}
        onClose={()=>setDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setDeleteAlert(false)}>No</Button>
          <Button onClick={deleteRecord} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <TextBox             
             label={"Youtube Subscribers"}
             id={"youtube_subscribers"}
             name={"youtube_subscribers"}
             autoFocus={true}                         
            value={youtube_subscribers}  
            isSelect={false}      
            onChange={(e)=>setYoutubeSucscribers(e.target.value)}
            />
             <TextBox             
             label={"Youtube Views"}
             id={"youtube_views"}
             name={"youtube_views"}
             autoFocus={true}                         
            value={youtube_views}  
            isSelect={false}      
            onChange={(e)=>setYoutubeViews(e.target.value)}
            />
             <TextBox             
             label={"Total Students"}
             id={"total_Students"}
             name={"total_Students"}
             autoFocus={false}                         
            value={total_Students}  
            isSelect={false}      
            onChange={(e)=>setTotalStudents(e.target.value)}
            />
             <SubmitButton 
              caption={"Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
            
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <DataTable 
                  columns={columns}
                  Data={!isLoading? youtubeData:[]}/>
                         </Paper>
                  </Grid>

         </Grid>
      </Container>

            </>
        )
    }
  return (
    <AdminTemplate>{otherInfoYoutubeScreen()}</AdminTemplate>
  )
}

export default OtherInfoYoutube