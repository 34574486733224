import React, { useState,useEffect } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Grid, Paper,Container, Button } from '@mui/material'
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComponentModal from './ComponentModal';
import TextBox from './InputFields/TextBox';
import SubmitButton from './InputFields/SubmitButton';
import {  useUpdateRecordedLiveMutation } from '../Services/modules/course';
//import{useUpdateCourseLessonMutation}from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Uploader } from '../utils/upload';
import CircularProgressWithLabel from './InputFields/CircularProgressWithLabel';
import { Box } from '@mui/system';
import VideoPlayer from './VideoPlayer';
import { Config } from '../Config';

function AddRecordedLiveSessions({course_recorded_live,course_id,refetch}) {
 // console.log("In add recorded live videos",course_recorded_live)
    const [expanded, setExpanded] = React.useState(false);
    const[isOpen,setIsOpen]=useState(false)
    const[selected_section,setSelectedSection]=useState("")
    const[isOpenLesson,setIsOpenLesson]=useState(false)
    const[video_caption,setVideoCaption]=useState("")
    const[live_session_community,setLiveSessionCommunity]=useState("")
    const[media_type,setMediaType]=useState("")
    const[isYoutubeLinkDisabled,setIsYoutubeLinkDisabled]=useState(true)
    const[video_link,setVideoLink]=useState("")
  const [video_file, setVideoFile] = useState(undefined)
  const [uploader, setUploader] = useState(undefined)
  const[progress_bar_percentage,setProgressBarPercentage]=useState(0)
  const[showProgressBar,setShowProgressBar]=useState(false)
  const[lesson_number,setLessonNumber]=useState("0")
  
    const community = [
      { key: "live_classes", value: "Live Classes" },
      { key: "recorded_courses", value: "Recorded Courses" },      
      { key: "both", value: "Both" },
     
    ];
    const [UpdateRecordedLive, { 
      data, 
      isSuccess, 
      isLoading, 
      isError, 
      error }] = useUpdateRecordedLiveMutation();
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    //    console.log("Panel",panel)
        setSelectedSection(panel)
      };
      useEffect(()=>{
        if(media_type=="youtube")
        {
          setIsYoutubeLinkDisabled(false)
        }
        else
        {
          setIsYoutubeLinkDisabled(true)

        }
      },[media_type])
      useEffect(() => {
    
        if (isSuccess) {
      //      console.log("InSuccess",data);
          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            toast.success(data.message)
           // setSelectedSection(data.lastAddedSection._id)
           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
    const upload_video=(section_id,lesson_id)=>{
   
      setLessonNumber(section_id)
     // setShowProgressBar(true)
  
    //  console.log("In upload file",video_file)
     var video_file_name=video_file.name.substring(0, video_file.name.lastIndexOf('.'))
     console.log(video_file_name)
      if (video_file) {
        const videoUploaderOptions = {
          fileName: video_file_name,
          file: video_file,
          course_id:course_id,
          section_id:section_id,
          lesson_id:lesson_id,
          content_type:"recorded_live"
        }
  
        let percentage = undefined
  
        const uploader = new Uploader(videoUploaderOptions)
        setUploader(uploader)
  
        uploader
          .onProgress(({ percentage: newPercentage }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              setShowProgressBar(true)
              percentage = newPercentage
              console.log(`${percentage}%`)
              setProgressBarPercentage(percentage)
             if(percentage==100)
             {
              toast.success("File uploaded successfully")
            setTimeout(()=>{
              setShowProgressBar(false)
            },2000)
             }
            }
          })
          .onError((error) => {
            setVideoFile(undefined)
            console.error(error)
          })
        
  
        uploader.start()
      }
      else
      {
        console.log("in else")
      }
    }
      
      function onSubmit (openstatus){
        if(openstatus=="saveandclose")
        {
         setTimeout(()=>{ setIsOpen(false)},2000)
         
        }
        if(openstatus=="close")
        {
          return setIsOpen(false)
        }
        UpdateRecordedLive({
        video_caption:video_caption,
        course_id:course_id,
        video_link:video_link,
        media_type:media_type,
        live_session_community:live_session_community
      })
    
     }
   
  return (
    <div>
      <ToastContainer />
      <ComponentModal isOpen={isOpen} setIsOpen={setIsOpen} caption={"Add Section"}>
       
      <TextBox 
              id={"video_caption"} 
              label={"Video Caption"} 
              name={"video_caption"} 
              autoFocus={true}
              value={video_caption}
              onChange={(e)=>setVideoCaption(e.target.value)}
              />
              
                <TextBox
               values={community}
               label={"For Community*"}
               id={"live_session_community"}
               name={"live_session_community"}
              isSelect={true}
              value={live_session_community} 
              onChange={(e)=>setLiveSessionCommunity(e.target.value)}

              />
              <TextBox
               values={[ 
                { key: "private", value: "Private" },
               { key: "youtube", value: "Youtube" },      
              ]}
               label={"Media Type*"}
               id={"media_type"}
               name={"media_type"}
              isSelect={true}
              value={media_type} 
              onChange={(e)=>setMediaType(e.target.value)}

              />
               <TextBox 
              id={"video_link"} 
              label={"Youtube link"} 
              name={"video_link"} 
              autoFocus={true}
              value={video_link}
              disabled={isYoutubeLinkDisabled}
              onChange={(e)=>setVideoLink(e.target.value)}
              />
               <Grid 
       container
       spacing={2}  
           
       >
        <Grid item xs={6} md={6} lg={6}>
        <SubmitButton 
              caption={"Save"}              
              onClick={()=>onSubmit("open")}
              />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
        <SubmitButton 
              caption={"Save And Close"}              
              onClick={()=>onSubmit("saveandclose")}
              />
        </Grid>
        <SubmitButton 
              caption={"Close"}              
              onClick={()=>onSubmit("close")}
              />
       </Grid>
             
               
            
      </ComponentModal>
      <Grid 
       container
       spacing={2}  
       mt={5}     
       >
        <Grid item xs={12} md={12} lg={4}>
                      <legend style={{fontWeight:"600"}}>Upload Live Sessions Recordings</legend>
                      </Grid>
                      </Grid>
                      <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    mt:2
                   
                  }}
                >
                   <Grid 
       container
       spacing={2}
       mb={5}
       >
         <Grid item xs={12} md={3} lg={3}>  
          <Button variant="outlined" onClick={()=>setIsOpen(true)}>Add Recorded Live Session</Button> 
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                 
                 

                    </Grid>  
                    </Grid>   
                { 
                course_recorded_live.map((item,i)=>{
                // console.log("check item Live",item)
                    return(
                     
                  <div>
                  
                    
                        <Accordion 
                        sx={{border:1}}
                        expanded={expanded === item._id} 
                        onChange={handleChange(item._id)}>
                        <AccordionSummary
                 expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel1bh-content"
                 id="panel1bh-header"
               >
                 <Typography sx={{ width: '40%',fontWeight:'600', flexShrink: 0 }}>
                  {item.live_session_caption}
                  
                 </Typography>
                 
               </AccordionSummary>
             
                    <AccordionDetails>
                    <Typography>
                    <Grid 
       container
       spacing={2}
       
       >
                   {item.media_type=="youtube"?
                   <>
                    <Grid item xs={4} md={2} lg={2}> 
                    <iframe width="560" height="315" 
                    src={`https://www.youtube.com/embed/${item.live_session_link}`}
                    
                    frameborder="0" allowfullscreen></iframe>  
    
                     </Grid> 
                     </> :
                     <>
                     <Grid item xs={4} md={3} lg={3}>  
                      <input
                      accept="video/*" 
  style={{marginLeft:"10px"}}
          type="file"
         
          onChange={(e) => {
            setVideoFile(e.target?.files?.[0])
          }}
        />   
         </Grid>  

         <Grid item xs={4} md={1} lg={1}>  
  <Button variant="contained" 
  size="small"
  onClick={()=>upload_video(item.section_number,0)}

  >
  Save
        </Button>
        </Grid>
        <Grid item xs={4} md={1} lg={1}> 
        <Button variant="contained" 
  size="small"
  onClick={()=>refetch()}

  >
  Refresh
        </Button>
        </Grid>  
        
        <Grid item xs={4} md={1} lg={1}> 
        {
 showProgressBar && item.section_number===lesson_number ?
  <CircularProgressWithLabel value={progress_bar_percentage}/>:
  ""
  }
        </Grid> 

        <Grid 
       container
       spacing={2}
       style={{marginTop:"20px"}}
       >
        <Grid item xs={12} md={4} lg={4}> 
      {item.live_session_link? 
      <VideoPlayer media_url={`${Config.API_CLOUDFRONT}${item.live_session_link}`}/>:""}
        </Grid>  
        </Grid>  
        </>
         }
                    
        </Grid> 

                    
                     
                    </Typography>
                  </AccordionDetails>
               
             </Accordion>
             </div>
             
                    )
                })
               }
               </Paper>
             

               
    
    </div>
  )
}

export default AddRecordedLiveSessions