import React from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'


function TextBox({id,label,name,autoFocus,inputType,onChange,value,acceptType,isSelect,values,multiline,disabled,onKeyPress}) {
  return (
    <div>
         <TextField
                margin="normal"                       
                fullWidth   
                select={isSelect}
                size="small"
                id={id}
                label={label}
                name={name}
                type={inputType}                
                autoFocus={autoFocus}
                onChange={onChange}
                value={value}  
                autocomplete="off"
                inputProps={{accept:"image/*"}}
                rows={8}   
                multiline={multiline}    
                disabled={disabled}  
                onKeyDown={onKeyPress}    

              > 

              
          
<MenuItem value="">
            <em>None</em>
          </MenuItem>
          {values &&
                  values.map(r => (
                    <MenuItem key={r.key} value={r.key}>{r.value}</MenuItem>                   
                  ))}
                
  </TextField>

    </div>
  )
}

export default TextBox