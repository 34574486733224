import { api } from '../../api'
import getStudentByUsername from './getStudentByUsername'

import login from './login'


export const loginApi = api.injectEndpoints({
    endpoints: build => ({
        Login: login(build), 
        GetStudentByUsername:getStudentByUsername(build)      
    }),
    overrideExisting: true,
})
export const { useLoginMutation,useGetStudentByUsernameMutation } = loginApi
//export const { useLoginMutation, useLogoutMutation, useSignUpMutation, useChangePasswordMutation, useVerifyOTPMutation, useCheckMobileMutation } = loginApi
