import { Box, Button, Grid, Paper } from '@mui/material'
import React,{useState,useEffect} from 'react'
import thumbnailImageAvtar from '../assets/img/01.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUploadThumbnailImageMutation } from '../Services/modules/course';
import { Config } from '../Config';
function AddCourseThumbnailImage({course_id,thumbnail_image_link}) {
 // alert(thumbnail_image_link)
 //console.log("thumbnail_image_link",thumbnail_image_link)
  let thumbnail_image_display=
  thumbnail_image_link!=="" && thumbnail_image_link!==undefined? 
  `${Config.API_FILE}/ThumbnailImages/${thumbnail_image_link}`:
  thumbnailImageAvtar
  const [UploadThumbnailImage, { 
    data, 
    isSuccess, 
    isLoading, 
    isError, 
    error }] = useUploadThumbnailImageMutation();

    useEffect(() => {
    
      if (isSuccess) {
         // console.log("InSuccess",data);
        
          if(data.error==true)
          {
            toast.error(data.message)
            return 
          }
          toast.success(data.message)
         // setSelectedSection(data.lastAddedSection._id)
         
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [isLoading]);
  
    const [thumbnail_image,setThumbnailImage]=useState([])
    const upload_image=()=>{
     // console.log("thumbnail_image",thumbnail_image)
      const form = new FormData();     
      form.append('thumbnail_image',thumbnail_image)

      form.append('course_id',course_id)

      UploadThumbnailImage(form)
    }
  return (
    <div>
         <Grid 
       container
       spacing={2}  
       mt={5}           
       >

        <Grid item xs={4} md={3} lg={3}>
                      <legend style={{fontWeight:"600",marginBottom:"20px"}}>
                      Course Thumbnail Image</legend>
                      </Grid>
                      </Grid>
             <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    mb:5
                   
                  }}
                >
         <Grid 
       container
       spacing={2}
       
       >
         <Grid item xs={4} md={3} lg={3}>  
                      <input
                      accept="image/*" 
  style={{marginLeft:"10px"}}
          type="file"
         id={thumbnail_image}
         name={thumbnail_image}
         //value={thumbnail_image}
          onChange={(e) => {
            console.log("e.target?.files?.[0]",e.target?.files?.[0])
            setThumbnailImage(e.target?.files?.[0])
          }}
        />   
         </Grid>  
         <Grid item xs={4} md={1} lg={1}>  
  <Button variant="contained" 
  size="small"
  onClick={
    ()=>upload_image()}

  >
  Save
        </Button>
        </Grid>  
        </Grid> 
        <Grid 
       container
       spacing={2}
       mt={10}
       ml={10}
       > 
      
       <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          mb: 5
        }}
        alt="Image Not Found"
        src={thumbnail_image_display}
      />
        {/* <img src={thumbnail_image_display} alt="BigCo Inc. logo"/> */}
        </Grid> 
       
        </Paper>
    </div>
  )
}

export default AddCourseThumbnailImage