import { api } from '../../api'

import getAllNotes from './getAllNotes'
import createNotes from './createNotes'
import deleteNotes from './deleteNotes'




export const notesApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllNotes: getAllNotes(build),         
        CreateNotes:createNotes(build),
        DeleteNotes:deleteNotes(build)
        
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {useCreateNotesMutation, useGetAllNotesQuery,useDeleteNotesMutation} = notesApi
