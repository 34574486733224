export default build =>
    build.mutation({
        query(payload) {
        console.log("In upload thumbnail image",payload)

            return {
                url: '/course/uploadthumbnailimage',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['courseById'],
    })