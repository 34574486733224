import React,{useEffect,useState} from 'react'
import { useLoginMutation } from '../Services/modules/auth';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../Store/UserData';
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Base from '../Components/Base';
import AdminTemplateNew from '../Components/Template/AdminTemplate';
import TextBox from '../Components/InputFields/TextBox';
import SubmitButton from '../Components/InputFields/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
    const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
   console.log("isLoggedin",isLoggedin)
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);

    const [state, setState] = React.useState({
      username:"",
      password:""
    })
    // const [validateInput, setValidateInput] = React.useState({
    //   errusername: "",
    //   errpassword:""
    // })
  
    const [Login, { data, isSuccess, isLoading, isError, error }] = useLoginMutation();
    const theme = createTheme();
    useEffect(() => {
    
        if (isSuccess) {
            console.log("InSuccess",data);
          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            dispatch(updateUserData({ data: data }))
           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);

    var payload = {
      email: state.username,
        password: state.password,
       
    };
     function onSubmit (){
    
      Login(payload)
   
   }
   const validateForm = () => {
    const { username, password } = state
    if (username !== "" && password !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };  
   
    function handleChange(e) {
      const value = e.target.value;

      setState({
        ...state,
        [e.target.name]: value
      });
      validateForm()
    }
    const handleKeypress = e => {
    
      if (e.keyCode === 13) {
        onSubmit();
      }
    };
   const loginScreen=()=>{
   
    return (
      isLoggedin?<Navigate to="/dashboard"/>: 
      <div>  
    
      <ThemeProvider theme={theme}>
      <ToastContainer />
          <CssBaseline />
          <Box
            sx={{
             
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}/>           
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box sx={{width: 400, mt: 1 }}>
              <TextBox 
              id={"username"} 
              label={"Username"} 
              name={"username"} 
              autoFocus={true}
              value={state.username}
              onChange={handleChange}
              />
              {/* <div>{validateInput.erremail}</div> */}
             
              <TextBox 
              id={"password"} 
              inputType={"password"} 
              label={"Password"} 
              name={"password"} 
              autoFocus={false}
              value={state.password}
              onChange={handleChange}
              onKeyPress={handleKeypress}

              />    
               {/* <div>{validateInput.errpassword}</div>   */}
                      
              <SubmitButton 
               
              caption={"Sign In"}            
              onClick={()=>onSubmit()}
              isLoading={isLoading}
              disabled={!isFormValid}
              
              />
            
            </Box>
          </Box>
       
        
        

      </ThemeProvider>
      
    </div>
    )
  
   }
 
  return(
   
    <AdminTemplateNew>
{loginScreen()}
</AdminTemplateNew>
   
  )
}

export default Login