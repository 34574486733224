import React,{useState,useEffect,useRef} from 'react'

import { useSearchParams } from 'react-router-dom';
//import { useQuery } from 'react-query'
import api from '../Services/api'
import { useSelector,useDispatch } from 'react-redux'
import Hls from 'hls.js';
import Plyr from 'plyr';
import "plyr-react/plyr.css"
//import WebTemplateVideos from '../Components/WebTemplateVideos';
import { NavLink, useNavigate } from 'react-router-dom';
//import black_player from '../assets/img/black_player.jpg'
import { Navigate } from 'react-router-dom';
//import { useGetRecordedVideosQuery } from '../Services/modules/course';

function VideoPlayer({media_url}) {
  const navigate = useNavigate()
  const ref = useRef()
    
  const[loading,setLoading]=useState(false)
   const[isEligible,setIsEligible]=useState(true)

  // const{data,isLoading,isSuccess}=useGetRecordedVideosQuery({course_id})
   
      useEffect(() => {
   
        const video = ref.current;
       
        const hls=new Hls()
        const defaultOptions={}
       // hls.loadSource("https://d2p2mde5k2x6t0.cloudfront.net/assets/f664817f-509f-45ea-86ae-f480fb296e73/HLS/test_64c3af8cb30a8f98034cad35_1_1.m3u8")
      // hls.loadSource("https://content.jwplatform.com/manifests/vM7nH0Kl.m3u8")
      // hls.loadSource("http://techslides.com/demos/sample-videos/small.mp4")
     //hls.loadSource( media_url)
     if(media_url!=="")
     {
      hls.loadSource(media_url)
     }
        hls.on(Hls.Events.MANIFEST_PARSED, function (event,data) {
        const availableQualities=hls.levels.map((l)=>l.height)
      
        defaultOptions.controls=[
          'play-large', 
         // 'restart', 
          'rewind', 
          'play', 
          'fast-forward', 
          'progress', 
          'current-time', 
          'duration', 
          'mute', 
          'volume', 
          'captions', 
          'settings', 
          //'pip',
         // 'airplay',
         
          'fullscreen',
        ];

         defaultOptions.quality={
          default:availableQualities[0],
          options:availableQualities,
          forced:true,
          onChange:(e)=>updateQuality(e)
         }
         const player=new Plyr(video,defaultOptions)    
         
        });
        hls.attachMedia(video);
        window.hls=hls
        function updateQuality(new_quality){
        window.hls.levels.forEach((level,levelIndex)=>{
          
          if(level.height===new_quality)
          {
            window.hls.currentLevel=levelIndex
          }
        })
        }
        // document.body.style.overflow = "hidden"
      
         
      },[media_url])
   
    
    
    const VideoPlayerScreen=()=>{
        return(
            <>
             
             
            
            {
              loading?<div className='loader'></div>:
             
             <>  

<div style={{ display: 'flex', justifyContent: 'center' }}>
     <video   
   //poster={black_player}
     id="plyr" 
     style={{ width: '100%', height: 'auto' }}   
     ref={ref}/>  
     
     </div>
   
     </>
                            
            }
          
            </>
        )
    }
  return (
    <>{VideoPlayerScreen()}</>
  )
}

export default VideoPlayer