import { api } from '../../api'

import createTestimonial from './createTestimonial'
import getTestimonials from './getTestimonials'
import deleteTestimonial from './deleteTestimonial'
import updateTestimonial from './updateTestimonial'

export const booksApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllTestimonials: getTestimonials(build),         
        CreateTestimonial:createTestimonial(build),
        UpdateTestimonial:updateTestimonial(build),
        DeleteTestimonial:deleteTestimonial(build)        
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {useCreateTestimonialMutation, useGetAllTestimonialsQuery,useDeleteTestimonialMutation,useUpdateTestimonialMutation} = booksApi
