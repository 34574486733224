import { Box, Button, Grid, Paper } from '@mui/material'
import React,{useState,useEffect} from 'react'
import thumbnailImageAvtar from '../assets/img/01.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  useUploadIntroVideoMutation, useUploadThumbnailImageMutation } from '../Services/modules/course';
import { Config } from '../Config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CircularProgressWithLabel from './InputFields/CircularProgressWithLabel';
function AddCourseIntroVideo({course_id,intro_video_link,refetch}) {
  const token = useSelector(state => state.userData.user_data.token);
 
 const[intro_video_state,setIntroVideoState]=useState("")
 const[is_video_loaded,setIsVideoLoaded]=useState(false)
 const [intro_video,setIntroVideo]=useState([])
 const[isUpload,setIsUpload]=useState(false)
 const[showProgressBar,setShowProgressBar]=useState("")
 const [uploadProgress, setUploadProgress] = useState(0);
// console.log("intro_video_link_kd",intro_video_link)
 useEffect(()=>{
  console.log("on load",intro_video_link)
 setIntroVideoState(intro_video_link)
setIsVideoLoaded(true)
 },[])
 useEffect(()=>{
  console.log("refetch called",intro_video_link)
 setIntroVideoState(intro_video_link)
  setIsVideoLoaded(true)
  
 },[intro_video_link])
 useEffect(() => {
  // Log the updated intro video state whenever it changes
  console.log('intro_video_state_use_effect:', intro_video_state);
  
}, [intro_video_state]);
    const upload_intro_video=()=>{
      console.log("intro_video",intro_video)
      setIntroVideoState("")
setIsVideoLoaded(false)

      const form = new FormData();     
      form.append('intro_video',intro_video)
      form.append('course_id',course_id)
     
      axios.post(`${Config.API_URL_LOCAL}course/uploadintrovideo`, 
      form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          setIsUpload(true)
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log('Upload Progress:', progress);
          setUploadProgress(progress);
        },
      })
      .then((response) => {
        console.log('Upload Complete:', response.data);
        toast.success("Video upload successfully")
        refetch()
        setIsUpload(false)
     
      })
      .catch((error) => {
        console.error('Upload Error:', error);
        // Handle error
      });

     
    }
  return (
    <div>
         <Grid 
       container
       spacing={2}  
       mt={5}           
       >

        <Grid item xs={4} md={3} lg={3}>
                      <legend style={{fontWeight:"600",marginBottom:"20px"}}>
                      Course Intro Video
                      <Grid item xs={4} md={1} lg={1}> 
      
        </Grid>  
                      </legend>
                      </Grid>
                      </Grid>
             <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    mb:5
                   
                  }}
                >
         <Grid 
       container
       spacing={2}
       
       >
         <Grid item xs={4} md={3} lg={3}>  
                      <input
                      accept="video/mp4" 
  style={{marginLeft:"10px"}}
          type="file"
         id={intro_video}
         name={intro_video}
         //value={thumbnail_image}
          onChange={(e) => {
            console.log("e.target?.files?.[0]",e.target?.files?.[0])
            setIntroVideo(e.target?.files?.[0])
          }}
        />   
         </Grid>  
         <Grid item xs={4} md={1} lg={1}>  
  <Button variant="contained" 
  size="small"
  disabled={isUpload}
  onClick={
    ()=>upload_intro_video()}

  >
  Save 
        </Button>
        </Grid>  
        <Grid item xs={4} md={1} lg={1}> 
        {
 isUpload ?
  <CircularProgressWithLabel value={uploadProgress}/>:
  ""
  }
        </Grid>  
        </Grid> 
        <Grid 
       container
       spacing={2}
       style={{marginTop:"20px"}}
       >
      
      <Grid item xs={4} md={4} lg={4}> 


      {intro_video_state!=="" && is_video_loaded? 
      <video controls style={{ maxWidth: '100%', maxHeight: '300px' }}>
          
            <source 
            src={`${Config.API_FILE}/CourseIntroVideos/${intro_video_state}`} type="video/mp4"></source>

            Your browser does not support the video tag.
          </video>:""}
        {intro_video_state}
          </Grid>  
       
        </Grid> 
       
        </Paper>
    </div>
  )
}

export default AddCourseIntroVideo