import React,{useEffect, useState} from 'react'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { Grid, Paper,Container, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'

import TextBox from '../Components/InputFields/TextBox'
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';

import SubmitButton from '../Components/InputFields/SubmitButton'
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAddPodcastMutation, useDeletePodcastMutation, useGetAllPodcastsQuery } from '../Services/modules/podcast'

function Podcast() {
    const[podcast_link,setPodcastLink]=useState("")
    const[updateRecord,setIsUpdateRecord]=useState(false)
    const[allPodcasts,setAllPodcasts]=useState([])
    const [isFormValid, setIsFormValid] = useState(false);
const[podcastId,setPodcastId]=useState("")
    const{data:podcastsData=[],isSuccess:isPodcastSuccess,isLoading:isPodcastLoading,error:isPodcastError}=useGetAllPodcastsQuery()
  const [AddPodcast, { data, isSuccess, isLoading, isError, error }] = useAddPodcastMutation();
  const [DeletePodcast, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteIsError, error:deleteError }] = useDeletePodcastMutation();
  const[showDialogDelete,setShowDialogDelete]=useState(false)

    const columns = [    
        { 
        key: "podcast_url_spotify", 
        title: "Podcast Link", 
        dataIndex: "podcast_url_spotify", 
        }, 
       
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">    
            <a onClick={()=>openDeleteAlertDialog(record)} style={{color:"green"}}>
              <DeleteIcon style={{fontSize:'medium'}}/> 
              </a>    
             
            </Space>
          ),
        },
        
        ] 
        const deletePodcast=(record)=>{
            DeletePodcast({podcastId})
        }
        const openDeleteAlertDialog=(record)=>{
          setPodcastId(record._id)
          setShowDialogDelete(true)
             }
             const closeDialog=()=>{
  
              setShowDialogDelete(false)
          
             }
        const validateForm = () => {
            
            if (podcast_link !== "" ) {
              setIsFormValid(true);
            } else {
              setIsFormValid(false);
            }
          };  
        const onSubmit=()=>{
            AddPodcast({podcast_link})
        }
        useEffect(()=>{
            if(isPodcastSuccess)
            {
                console.log("podcastsData",podcastsData)
                setAllPodcasts(podcastsData.podcasts)
            }
        },[podcastsData])
        useEffect(()=>{
            if(isSuccess)
            {
                console.log("data",data)
                
            }
        },[data])
        useEffect(()=>{
            if(deleteSuccess)
            {
                console.log("podcastsData",deleteData)
                toast.success(deleteData.message)
            }
        },[deleteData])
    const podcastScreen=()=>{
        return(
            <>
           <ToastContainer />
           <Dialog
        open={showDialogDelete}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button onClick={deletePodcast} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

<Grid
container
spacing={5}
direction="row"
alignItems="center"
justify="center"
>
<Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div>         
        <TextBox             
             label={"Add Spotify Link"}
             id={"podcast_link"}
             name={"podcast_link"}
             autoFocus={true}                         
            value={podcast_link}  
            isSelect={false}      
            onChange={(e)=>setPodcastLink(e.target.value)}
            />  
        </div>
       
            <SubmitButton 
              caption={updateRecord? "update": "Save"}             
              button_color={'green'}
              onClick={()=>onSubmit()}
              isLoading={isLoading}
            //   disabled={!isFormValid}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allPodcasts}/>
                       
                         </Paper>
                  </Grid>
         
</Grid>
            </>
        )

    }
  return (
    <AdminTemplate>{podcastScreen()}</AdminTemplate>
  )
}

export default Podcast