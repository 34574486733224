export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
              //  url: '/course/addCourseIntroVideo',
              url: '/course/uploadintrovideo',

                method: 'POST',
                body: payload,
               
            };
        },
       invalidatesTags: ['courseById'],
    })
