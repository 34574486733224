import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import Mail from '@mui/icons-material/Mail';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';
import { updateCourseData } from '../Store/CourseData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate  } from "react-router-dom";
import { useCreateStudentMutation, useDeleteStudentMutation, useGetAllStudentsQuery, useUpdateStudentMutation } from '../Services/modules/liveCourses'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function CreateStudent() {
  const[isupdateRecord,setUpdateRecord]=useState(false);
  const[studentId,setStudentId]=useState("")
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)

  const navigate=useNavigate ()
  const dispatch = useDispatch();

  const [createStudent, { data, isSuccess, isLoading, isError, error }] = useCreateStudentMutation();
  const [updateStudent, { data:updatedStudentData, isSuccess:updatedStudentSuccess, isLoading:updatedStudentLoading, isError:updateStudentError }] = useUpdateStudentMutation();
  const [deleteStudent, { data:deleteStudentData, isSuccess:deleteStudentSuccess, isLoading:deleteStudentLoading, isError:deleteStudentError }] = useDeleteStudentMutation();

  const { 
    data: students= [],  
    isLoading:loading=true,
    isSuccess:getAllStudentsSuccess=false
   } = useGetAllStudentsQuery();
   const columns = [ 
   
    { 
    key: "student_name", 
    title: "Student Name", 
    dataIndex: "student_name", 
    }, 
  
    { 
    key: "course_name", 
    title: "Course Name", 
    dataIndex: "course_name", 
    }, 
    { 
      key: "course_duration", 
      title: "Duration", 
      dataIndex: "course_duration", 
      }, 
      { 
        key: "country", 
        title: "Country", 
        dataIndex: "country", 
        }, 
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
               
          {/* <a onClick={()=>createSchoolProfile(record)} style={{color: "blue"}}>Profile </a>  */}
          <a onClick={()=>editStudent(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
          <a onClick={()=>deleteStudentConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>
        
        </Space>
      ),
    },
   
    ] 
    const editStudent = (record) => {
        // Populate input fields for editing
      
        setStudent({
            student_id:record._id,
          admission_type: record.admission_type,
          student_photo: record.student_photo,
          student_name: record.student_name,
          mobile_number: record.mobile_number,
          country_code: record.country_code,
          country: record.country,
          college_Name: record.college_Name,
          address: record.address,
          state: record.state,
          DOB: record.DOB,
          course_id: record.course_id,
          course_name: record.course_name,
          course_fees: record.course_fees,
          admission_status: record.admission_status,
          payment_status: record.payment_status,
        });
    
        // Update the state variable that indicates editing mode
        setUpdateRecord(true);
      };
      const deleteStudentConfirmAlert=(record)=>{
        //dispatch(clearStaffData())
        setStudentId(record._id)
        setDeleteDialogOpen(true)
       
    
       }
    const deleteNewStudent=()=>{
        deleteStudent({student_id:studentId})
        setDeleteDialogOpen(false)
    }
    const closeConfirmAlert=()=>{
    
        setDeleteDialogOpen(false)
    
       }
    const live_courses = [
        { key: "English communication and confidence Therapy", value: "English communication and confidence Therapy" },
        { key: "Leadership DNA", value: "Leadership DNA" },      
        
      ];
    const[Student,setStudent]=useState({
        student_id:"",
        admission_type:"",//new,old
        student_photo:"",
        student_name:"",
        // mobile_number:"",
        // country_code:"",
        country:"",
        college_Name:"",
        address:"",
        state:"",
        DOB:"",
        course_id:"",
        course_name:"",
        course_fees:"",
        course_duration:"",
        admission_status:"",
        payment_status:"", 
        username:""// unique for admission          
      })  
     
    function handleChange(e) {
        var value = "";
      
        value=e.target.value
      
        setStudent({
          ...Student,
          [e.target.name]: value
        });
      }
      useEffect(() => {    
        if (isSuccess) {
            console.log("InSuccess",data);          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            toast.success(data.message)
            setStudent({
                admission_type: "",
                student_photo: "",
                student_name: "",
                // mobile_number: "",
                // country_code: "",
                country: "",
                college_Name: "",
                address: "",
                state: "",
                DOB: "",
                course_id: "",
                course_name: "",
                course_fees: "",
                course_duration:"",
                admission_status: "",
                payment_status: "",
                username:""
              });
           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
    useEffect(() => {    
        if (updatedStudentSuccess) {
            console.log("InSuccess",updatedStudentData); 

            if(updatedStudentData.error==true)
            {
              toast.error(updatedStudentData.message)
              return 
            }
            toast.success(updatedStudentData.message)
           
            setStudent({
                student_id:"",
                admission_type: "",
                student_photo: "",
                student_name: "",
                // mobile_number: "",
                // country_code: "",
                country: "",
                college_Name: "",
                address: "",
                state: "",
                DOB: "",
                course_id: "",
                course_name: "",
                course_fees: "",
                course_duration:"",
                admission_status: "",
                payment_status: "",
                username:""
              });
              setUpdateRecord(false)
        }
        if (isError) {
            alert("In error")
            console.log(error);
            setUpdateRecord(false)
        }
    }, [updatedStudentLoading]);
    useEffect(() => {   
     
      if(getAllStudentsSuccess)
      {
        console.log("students",students)
        }
      
    }, [getAllStudentsSuccess]);

   
   
    function onSubmit (){    
        console.log("In on submit",Student)
        if(isupdateRecord)
        {
        // var checkvalidate=  validate()
       //  if(checkvalidate)
        // {
            updateStudent(Student)
         //}
        }
        else
        {
          //var checkvalidate=  validate()
        //  if(checkvalidate)
         // {
            createStudent(Student)
           
         // }
        }
       
        
     // }  
    
     }
    const CreateStudentScreen=()=>{
        return(
            <Container alignItems="center" sx={{mt:5}}>
       <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteNewStudent} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                              <TextBox             
             label={"Student Name"}
             id={"student_name"}
             name={"student_name"}
             autoFocus={true}                         
            value={Student.student_name}  
            isSelect={false}      
            onChange={handleChange}

            />
            <TextBox             
             label={"Country"}
             id={"country"}
             name={"country"}                                    
            value={Student.country}  
            isSelect={false}  
            onChange={handleChange}

            />
               <TextBox             
             label={"Username Or Email Id"}
             id={"username"}
             name={"username"}                                    
            value={Student.username}  
            isSelect={false}  
            onChange={handleChange}

            />
            
              {/* <TextBox             
             label={"Country Code"}
             id={"country_code"}
             name={"country_code"}                                    
            value={Student.country_code}  
            isSelect={false}  
            onChange={handleChange}

            />
             <TextBox             
             label={"Mobile Number"}
             id={"mobile_number"}
             name={"mobile_number"}                                    
            value={Student.mobile_number}  
            isSelect={false}  
            onChange={handleChange}

            /> */}
              <TextBox
               values={live_courses}
               label={"Course Name*"}
               id={"course_name"}
               name={"course_name"}
              isSelect={true}
              value={Student.course_name}  
              onChange={handleChange}
              />
               <TextBox             
             label={"Course Fees"}
             id={"course_fees"}
             name={"course_fees"}                                    
            value={Student.course_fees}  
            isSelect={false}  
            onChange={handleChange}
            />
             <TextBox             
             label={"Course Duration"}
             id={"course_duration"}
             name={"course_duration"}                                    
            value={Student.course_duration}  
            isSelect={false}  
            onChange={handleChange}
            />
            <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={!loading? students.allStudents:[]}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
        )
    }
  return (
   <AdminTemplate>
    {CreateStudentScreen()}
   </AdminTemplate>
  )
}

export default CreateStudent