import React, { useState,useEffect } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Grid, Paper,Container, Button, Tooltip, FormControlLabel, Checkbox } from '@mui/material'
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComponentModal from './ComponentModal';
import TextBox from './InputFields/TextBox';
import SubmitButton from './InputFields/SubmitButton';
import {useUpdateLessonExistingMediaMutation, useDeleteLessonMutation, useDeleteSectionMutation, useEditLessonNameMutation, useEditSectionNameMutation, useGetAllRecordedVideosQuery, useUpdateCourseLessonMutation, useUpdateCourseSectionMutation } from '../Services/modules/course';
//import{useUpdateCourseLessonMutation}from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Uploader } from '../utils/upload';
import CircularProgressWithLabel from './InputFields/CircularProgressWithLabel';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import VideoPlayer from './VideoPlayer';
import { Config } from '../Config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';

function AddRecordedVideos({course_section,course_id,refetch}) {
  //console.log("In add recorded videos",course_section)
  //console.log("course_section",course_section)
  const allRecordedVideosGlobal = useSelector(state => state.allRecordedVideos?.allRecordedVideos);
//console.log("allRecordedVideos from store",allRecordedVideosGlobal)

    const [expanded, setExpanded] = React.useState(false);
    const[isOpen,setIsOpen]=useState(false)
    const[selected_section,setSelectedSection]=useState("")
    const[isOpenLesson,setIsOpenLesson]=useState(false)
    const [section_id, setSectionId] = useState("");
   
    const[section_name,setSectionName]=useState("")
const[isSectionUpdate,setIsSectionUpdate]=useState(false)
const[isLessonUpdate,setIsLessonUpdate]=useState(false)
const [lesson_id, setLessonId] = useState("");

    const[lesson_number,setLessonNumber]=useState("0")
    const[lesson_name,setLessonName]=useState("")
    const[lesson_description,setLessonDescription]=useState("")
    const[video_name,setVideoName]=useState("")
const[media_url,setMediaUrl]=useState("")
const[media_url_youtube,setMediaUrlYoutube]=useState("")

const[media_type,setMediaType]=useState("")

    const[lesson_community,setLessonCommunity]=useState("")

    const [validateSectionName, setValidateInput] =useState("")
  const [video_file, setVideoFile] = useState(undefined)
  const [uploader, setUploader] = useState(undefined)
const[progress_bar_percentage,setProgressBarPercentage]=useState(0)
const[showProgressBar,setShowProgressBar]=useState(false)
const[showDialogDeleteSection,setShowDialogDeleteSection]=useState(false)
const[showDialogDeleteLesson,setShowDialogDeleteLesson]=useState(false)
const[courseSection,setCourseSection]=useState([])

const[showDialogSelectExistingVideos,setShowDialogSelectExistingVideos]=useState(false)
const [selectedOption, setSelectedOption] = useState(null);
const [selectedOptionId, setSelectedOptionId] = useState(null);

const community = [
  { key: "live_classes", value: "Live Classes" },
  { key: "recorded_courses", value: "Recorded Courses" },      
  { key: "both", value: "Both" },
 
];
const onDragStart = () => {};
const onDragEnd = () => {};
const { 
  data: allRecordedVideos= [],  
  isLoading:loading=true, 
  isSuccess:getAllVideosSuccess=false
 } = useGetAllRecordedVideosQuery();

    const [UpdateCourseSection, { 
      data, 
      isSuccess, 
      isLoading, 
      isError, 
      error }] = useUpdateCourseSectionMutation();

      const [UpdateLessonExistingMedia, { 
        data:existingVideoData, 
        isSuccess:existingVideoSuccess, 
        isLoading:existingVideoLoading, 
        isError:existingVideoError, 
        }] = useUpdateLessonExistingMediaMutation();

    

      const [EditSectionName, { data:sectionDataUpdate, isSuccess:isSectionSuccessUpdate, isLoading:isSectionLoadingUpdate, isError:isSectionErrorUpdate}] = useEditSectionNameMutation();
      const [DeleteSection, { data:sectionDataDelete, isSuccess:isSectionSuccessDelete, isLoading:isSectionLoadingDelete, isError:isSectionErrorDelete}] = useDeleteSectionMutation();

      const [EditLessonName, { data:lessonDataUpdate, isSuccess:isLessonSuccessUpdate, isLoading:isLessonLoadingUpdate, isError:isLessonErrorUpdate}] = useEditLessonNameMutation();
      const [DeleteLesson, { data:lessonDataDelete, isSuccess:isLessonSuccessDelete, isLoading:isLessonLoadingDelete, isError:isLessonErrorDelete}] = useDeleteLessonMutation();


    const [UpdateCourseLesson, { 
      data:lesson_data, 
      isSuccess:is_lesson_success, 
      isLoading:is_lesson_loading, 
      isError:is_lesson_error, 
      }] = useUpdateCourseLessonMutation();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      //  console.log("Panelll",panel)
        setSelectedSection(panel)
      };
      const clearSection=()=>{
        setSectionName("")
        setSectionId("")
      
    }
    const clearLesson=()=>{
      setLessonName("")
      setLessonCommunity("")
      setLessonDescription("")
      setVideoName("")
      setLessonId("")
      setSectionId("")
    
  }
  const handleVideoCheckboxChange=(video_url,video_id)=>{
    setSelectedOptionId(video_id)

    setSelectedOption(video_url)
  }
  const openVideosModel=(lesson_id)=>{
   // alert(lesson_id)
    setLessonId(lesson_id)
    setShowDialogSelectExistingVideos(true)
  }
  useEffect(()=>{
    console.log("refetch called",course_section)
    setCourseSection([])

     //setCourseSection(course_section)
     setCourseSection(course_section)
  
   // setCourseSection([])
  
  //  setIntroVideoState(intro_video_link)
  //   setIsVideoLoaded(true)
    
   },[course_section])
  useEffect(()=>{
    if(getAllVideosSuccess)
    {
      console.log("All recorded videos",allRecordedVideos)
    }
  },[allRecordedVideos])
  useEffect(()=>{
    setCourseSection(course_section)
  },[])
      useEffect(() => {
    
        if (isSuccess) {
          //  console.log("InSuccess",data);
          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            toast.success(data.message)
            setSelectedSection(data.lastAddedSection._id)
           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
  
    useEffect(() => {
    
      if (existingVideoSuccess) {
          console.log("InSuccessexsiting",existingVideoData);
        
          if(existingVideoData.error==true)
          {
            toast.error(existingVideoData.message)
            return 
          }
          toast.success(existingVideoData.message)
        //  setSelectedSection(data.lastAddedSection._id)
         setShowDialogSelectExistingVideos(false)
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [existingVideoData]);
    useEffect(() => {
    
      if (is_lesson_success) {
        //  console.log("InSuccess",lesson_data);
        
          if(lesson_data.error==true)
          {
            toast.error(lesson_data.message)
            return 
          }
          toast.success(lesson_data.message)
         // setSelectedSection(data.lastAddedSection._id)
         
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [is_lesson_loading]);
 
  useEffect(()=>{
    if(isSectionSuccessUpdate)
    {
        toast.success(sectionDataUpdate.message)
        setIsSectionUpdate(false)
        setIsOpen(false)
        clearSection()
    }
  },[sectionDataUpdate])
  useEffect(() => {
    setSectionId("")
    setShowDialogDeleteSection(false)
    if (isSectionSuccessDelete) {
        console.log("InSuccess",sectionDataDelete);
      
        if(sectionDataDelete.error==true)
        {
          toast.error(sectionDataDelete.message)
          return 
        }
        toast.success(sectionDataDelete.message)
       // setSelectedSection(data.lastAddedSection._id)
       
    }
    if (isError) {
        alert("In error")
        console.log(error);
    }
}, [sectionDataDelete]);

useEffect(()=>{
  if(isLessonSuccessUpdate)
  {
      toast.success(lessonDataUpdate.message)
      setIsLessonUpdate(false)
      setIsOpen(false)
      clearLesson()
  }
},[lessonDataUpdate])
useEffect(() => {
  setLessonId("")
  setSectionId("")
 
  setShowDialogDeleteLesson(false)
  if (isLessonSuccessDelete) {
      console.log("InSuccess",lessonDataDelete);
    
      if(lessonDataDelete.error==true)
      {
        toast.error(lessonDataDelete.message)
        return 
      }
      toast.success(lessonDataDelete.message)
     // setSelectedSection(data.lastAddedSection._id)
     
  }
  if (isError) {
      alert("In error")
      console.log(error);
  }
}, [lessonDataDelete]);


  const upload_video=(section_id,lesson_id)=>{
   
    setLessonNumber(lesson_id)
   // setShowProgressBar(true)

  //  console.log("In upload file",video_file)
   //var video_file_name=video_file.name.substring(0, video_file.name.lastIndexOf('.'))//original
  // let video_file_name = video_file.name.replace(/ /g, "-").substring(0, video_file.name.lastIndexOf('.'));//working without dot

  const lastPeriodIndex = video_file.name.lastIndexOf('.');
  let video_file_name = video_file.name.replace(/ /g, "-").replace(/\./g, (match, index) => {
    return index === lastPeriodIndex ? match : "-";
  }).substring(0, video_file.name.lastIndexOf('.'));
 
    if (video_file) {
      const videoUploaderOptions = {
        fileName: video_file_name,
        file: video_file,
        course_id:course_id,
        section_id:section_id,
        lesson_id:lesson_id,
        content_type:"recorded_video"
      }

      let percentage = undefined

      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            setShowProgressBar(true)
            percentage = newPercentage
            console.log(`${percentage}%`)
            setProgressBarPercentage(percentage)
           if(percentage==100)
           {
            toast.success("File uploaded successfully")
          setTimeout(()=>{
            setShowProgressBar(false)
          },2000)
           }
          }
        })
        .onError((error) => {
          setVideoFile(undefined)
          console.error(error)
        })
      

      uploader.start()
    }
    else
    {
      console.log("in else")
    }
  }
      
      function onSubmit (openstatus){
       
        if(openstatus=="saveandclose")
        {
         setTimeout(()=>{ setIsOpen(false)},2000)
         
        }
        if(openstatus=="close")
        {
          return setIsOpen(false)
        }
        if(isSectionUpdate)
        {
          EditSectionName({
                course_id:course_id,
                section_id:section_id,
                new_section_name:section_name
            })
        }
        else
        {
          UpdateCourseSection({section_name:section_name,course_id:course_id})

        }
     // }  
    
     }
     function onSubmitLesson (openstatus){
     //console.log("selected_section",selected_section)
     var section_id=selected_section
     console.log("section_id",section_id)
     console.log("LessonComminityyyy",lesson_community)
     if(openstatus=="saveandclose")
     {
      setTimeout(()=>{ setIsOpenLesson(false)},2000)
      
     }
     if(openstatus=="close")
     {
       return setIsOpenLesson(false)
     }
     if(isLessonUpdate)
     {
       EditLessonName({
        section_id:section_id,
        lesson_id:lesson_id,
             course_id:course_id,
             new_lesson_name:lesson_name,
             lesson_description:lesson_description,
             lesson_community:lesson_community,
             video_name:video_name,
             media_type:media_type,
             media_url:media_url
            
         })
     }
     else
     {
      UpdateCourseLesson(
        {section_id:section_id,
          course_id:course_id,
          lesson_name:lesson_name,
          lesson_description:lesson_description,
          lesson_community:lesson_community,
          video_name:video_name,
          media_type:media_type,
          media_url:media_url
         
        })
       
     }
   
   
   }
   function onSubmitExistingMedia (){
    //console.log("selected_section",selected_section)
   
    console.log("section_id",section_id)
    
   
    UpdateLessonExistingMedia(
       {lesson_id:lesson_id,
         course_id:course_id,
         media_url:selectedOption,
        
       })
      
    
  
  
  }
   
   const updateSectionName=(section_id,section_name)=>{
    console.log("In update lesson",section_name,section_id)
    setSectionId(section_id)
setSectionName(section_name)
setIsSectionUpdate(true)
setIsOpen(true)
  }
  const deleteSectionName=(section_id,lesson_length)=>{
    console.log("lesson_length",lesson_length)
    console.log("In delete section",section_id)
    if(lesson_length>0)
    {
        return alert("First delete lessons inside")
    }
    setSectionId(section_id)
    setShowDialogDeleteSection(true)
  }

  const deleteCourseSection=()=>{
    DeleteSection({course_id:course_id,section_id:section_id})
  }
  const closeConfirmAlert=()=>{
  
    setShowDialogDeleteSection(false)

   }
   const closeConfirmAlertVideos=()=>{
  
    setShowDialogSelectExistingVideos(false)

   }

   const updateLessonName=(section_id,lesson_id,lesson_name,lesson_community,lesson_description,video_name,media_type,media_url)=>{
    console.log("In update lesson",lesson_name,lesson_id)
   // alert(media_url)
    setSectionId(section_id)
    setLessonId(lesson_id)
setLessonName(lesson_name)
setLessonDescription(lesson_description)
setLessonCommunity(lesson_community)
setVideoName(video_name)
setMediaType(media_type)
setMediaUrl(media_url)
setIsLessonUpdate(true)
setIsOpenLesson(true)
  }
  const deleteLessonName=(section_id,lesson_id)=>{
   
    console.log("In delete lesson",lesson_id)
    setSectionId(section_id)
    setLessonId(lesson_id)
    setShowDialogDeleteLesson(true)
  }
  const deleteCourseLesson=()=>{
    DeleteLesson({course_id:course_id,lesson_id:lesson_id,section_id:section_id})
  }
  const handleRefetch=()=>{
    refetch()
  }
  return (
    <div>
       
       <Dialog
        open={showDialogDeleteSection}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteCourseSection} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialogDeleteLesson}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteCourseLesson} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <ComponentModal isOpen={showDialogSelectExistingVideos} 
      setIsOpen={closeConfirmAlertVideos} 
      caption={"Add Existing Video"}>
     
     
           {
            allRecordedVideosGlobal.map((data,index)=>{
             // console.log("dataaaaaa",data)
              return(
                <Grid container alignItems="center">
     { data.media_url!==""?  <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedOptionId === data.video_id}
                onChange={() => handleVideoCheckboxChange(data.media_url,data.video_id)}
                name={`video_${index}`}
              />
            }
            label=""
          />
        </Grid>:""}
                <Grid item xs={11} md={11} lg={11} style={{marginTop:"20px"}}>

             {data.media_url_type!=="youtube"? 
             <>
             {data.video_name}
              <VideoPlayer media_url={`${Config.API_CLOUDFRONT}${data.media_url}`}/>
             
             </>
             
             :""
             }
             
               </Grid>  
               </Grid>
              )
            })
           }
     
       
               
                 <Grid 
        container
        spacing={2}  
            
        >
          <Grid item xs={12} md={12} lg={12}>
          <SubmitButton 
          //{isSectionUpdate?"Update Section": "Save Section"}
                caption={"Save"}              
                onClick={()=>onSubmitExistingMedia()}
                />
          </Grid>
         
                
        </Grid>
      
       </ComponentModal>

      <ComponentModal isOpen={isOpen} setIsOpen={setIsOpen} caption={"Add Section"}>
       
      <TextBox 
              id={"section_name"} 
              label={"Section Name"} 
              name={"section_name"} 
              autoFocus={true}
              value={section_name}
              onChange={(e)=>setSectionName(e.target.value)}
              />
             
              
                <Grid 
       container
       spacing={2}  
           
       >
         <Grid item xs={6} md={6} lg={6}>
         <SubmitButton 
         //{isSectionUpdate?"Update Section": "Save Section"}
               caption={isSectionUpdate?"Update": "Save"}              
               onClick={()=>onSubmit("open")}
               />
         </Grid>
         <Grid item xs={6} md={6} lg={6}>
         <SubmitButton 
               caption={isSectionUpdate?"Update & Close":"Save & Close"}              
               onClick={()=>onSubmit("saveandclose")}
               />
         </Grid>
               
       </Grid>
       <SubmitButton 
               caption={"Close"}              
               onClick={()=>onSubmit("close")}
               />
            
      </ComponentModal>
      <ComponentModal isOpen={isOpenLesson} setIsOpen={setIsOpenLesson} caption={"Add Lesson"}>
       
       <TextBox 
               id={"lesson_name"} 
               label={"Lesson Name"} 
               name={"lesson_name"} 
               autoFocus={true}
               value={lesson_name}
               onChange={(e)=>setLessonName(e.target.value)}
               />
               <TextBox
               values={community}
               label={"For Community*"}
               id={"lesson_community"}
               name={"lesson_community"}
              isSelect={true}
              value={lesson_community} 
              onChange={(e)=>setLessonCommunity(e.target.value)}

              />
                <TextBox 
               id={"lesson_description"} 
               label={"Lesson Description"} 
               name={"lesson_description"} 
               autoFocus={true}
               value={lesson_description}
               onChange={(e)=>setLessonDescription(e.target.value)}
               />
                <TextBox 
               id={"video_name"} 
               label={"Video Name"} 
               name={"video_name"} 
               autoFocus={true}
               value={video_name}
               onChange={(e)=>setVideoName(e.target.value)}
               />
                 <TextBox 
               id={"media_type"} 
               label={"Media Type"} 
               name={"media_type"} 
               autoFocus={false}
               value={media_type}
               onChange={(e)=>setMediaType(e.target.value)}
               />
                <TextBox 
               id={"media_url"} 
               label={"Media Url"} 
               name={"media_url"} 
               autoFocus={false}
               value={media_url}
               onChange={(e)=>setMediaUrl(e.target.value)}
               />
               <Grid 
       container
       spacing={2}  
           
       >
         <Grid item xs={6} md={6} lg={6}>
         <SubmitButton 
               caption={isLessonUpdate?"Update":"Save"}              
           
               onClick={()=>onSubmitLesson("open")}
               />
         </Grid>
         <Grid item xs={6} md={6} lg={6}>
         <SubmitButton 
               caption={isLessonUpdate?"Update & Close":"Save & Close"}              
                        
               onClick={()=>onSubmitLesson("saveandclose")}
               />
         </Grid>
               
       </Grid>
       
         <SubmitButton 
               caption={"Close"}              
               onClick={()=>onSubmitLesson("close")}
               />
       
               
             
       </ComponentModal>
       <Grid 
       container
       spacing={2}  
           
       >
        <Grid item xs={4} md={3} lg={3}>
                      <legend style={{fontWeight:"600"}}>Upload Recorded Courses</legend>
                      </Grid>
                      </Grid>
                      <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    mt:3
                   
                  }}
                >
                   <Grid 
       container
       spacing={2}
       mb={5}
       >
         <Grid item xs={12} md={3} lg={3}>  
          <Button variant="outlined" onClick={()=>setIsOpen(true)}>Add Course Section</Button> 
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                 
                  <Button variant="outlined" onClick={()=>setIsOpenLesson(true)}>
                    Add Lesson
                    </Button>
                   

                    </Grid>  
                    </Grid>   
                { 
                courseSection?.map((item,i)=>{
                   // console.log("itemmmmm",item)
                    return(
                    
                       
                  <div>
                  
                    
                        <Accordion 
                         sx={{border:1}}
                        expanded={expanded === item._id} 
                        onChange={handleChange(item._id)}>
                        <AccordionSummary
                 expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel1bh-content"
                 id="panel1bh-header"
                 sx={{ borderBottom:1}}
                 
               >
                 <Typography 
                 sx={{ width: '40%',
                 fontWeight:'600',
                 
                 flexShrink: 0 }}>
                  {item.section_name}
                  <i 
                  class="fa fa-edit"
 aria-hidden="true"
  style={{marginLeft:"10px"}}
   onClick={()=>updateSectionName(item._id,item.section_name)}
  />
   <i class="fa fa-trash" 
  onClick={()=>deleteSectionName(item._id,item.lessons.length)}
  aria-hidden="true"
   style={{marginLeft:"20px"}}></i>
                 </Typography>
                 
               </AccordionSummary>
             { 
             item.lessons.map((lessons,i)=>{
            //  console.log("lessonssssss",lessons)
                return(
                    <AccordionDetails 
                     sx={{borderBottom:1}}
                     style={{paddingTop:"30px",paddingBottom:"30px"}}
                     >
                    <Typography>
                  
                    <Grid 
       container
       spacing={2}
       
       >
         <Grid item xs={4} md={2} lg={2}>  
         {
        // console.log("lessons",i)
         }
                     {i+1} 
                     {/* <Tooltip title="Change Lesson Sequence">
<EditIcon style={{fontSize:'medium'}}/> 
</Tooltip> */}
 <i 
                  class="fa fa-edit"
 aria-hidden="true"
  style={{marginLeft:"10px"}}
   onClick={()=>updateLessonName(item._id,lessons._id,lessons.lesson_name,lessons.lesson_community,lessons.lesson_description,lessons.video_name,lessons.media_type,lessons.media_url_youtube)}
  />
   <i class="fa fa-trash" 
  onClick={()=>deleteLessonName(item._id,lessons._id)}
  aria-hidden="true"
   style={{marginLeft:"20px"}}></i>

                       
                     </Grid> 
                    <Grid item xs={4} md={2} lg={2}>  
                     {lessons.lesson_name} | {lessons.lesson_community}  
                     </Grid>  
                     <Grid item xs={4} md={3} lg={3}>  
                      <input
                      accept="video/*" 
  style={{marginLeft:"10px"}}
          type="file"
         
          onChange={(e) => {
            setVideoFile(e.target?.files?.[0])
          }}
        />   
         </Grid>  
        

         <Grid item xs={4} md={1} lg={1}>  
  <Button variant="contained" 
  size="small"
  onClick={()=>upload_video(item.section_number,lessons.lesson_number)}

  >
  Save
        </Button>
        </Grid>  
        <Grid item xs={4} md={1} lg={1}> 
        <Button variant="contained" 
  size="small"
  //onClick={()=>refetch()}
  onClick={()=>handleRefetch()}


  >
  Refresh
        </Button>
       
        </Grid>  
        <Grid item xs={4} md={1} lg={1}> 
        <Button variant="contained" 
  size="small"
  //onClick={()=>refetch()}
  onClick={()=>openVideosModel(lessons._id)}


  >
  Existing Videos
        </Button>
        </Grid>  

       
        
        <Grid item xs={4} md={1} lg={1}> 
        {
 showProgressBar && lessons.lesson_number===lesson_number ?
  <CircularProgressWithLabel value={progress_bar_percentage}/>:
  ""
  }
        </Grid>  
        
        </Grid> 
        <Grid 
       container
       spacing={2}
       style={{marginTop:"20px"}}
       >
        <Grid item xs={12} md={4} lg={4}> 
      {lessons.media_type!=="youtube"? 
      <VideoPlayer media_url={`${Config.API_CLOUDFRONT}${lessons.media_url}`}/>
      :
     
             <>
              <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${lessons.media_url_youtube}`}
      frameBorder="0"
      allowFullScreen
    />
             </>
      
      }
        </Grid>  
        </Grid>  
                    
                    </Typography>
                  </AccordionDetails>
                )
             })
             }
             </Accordion>
             </div>
             
                    )
                })
               }
               </Paper>
             
             

               
    
    </div>
  )
}

export default AddRecordedVideos