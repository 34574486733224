import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'allRecordedVideos',
    initialState: { allRecordedVideos: [{}] },
    reducers: {
        allRecordedVideosData: (state, { payload: { data } }) => {
            state.allRecordedVideos = data
        },
      
    },
})

export const { allRecordedVideosData} = slice.actions

export default slice.reducer
