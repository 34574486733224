import React,{useEffect,useState,useRef} from 'react'

import { useDispatch,useSelector } from 'react-redux';
import AdminTemplate from '../Components/Template/AdminTemplate';
//import DashboardSchool from './SchoolAdmin/DashboardSchool';

//import SuperadminDashboard from './SuperAdmin/SuperadminDashboard';
//import { useCreateSchoolMutation } from '../Services/modules/school';

function Dashboard() {
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
const DasgboardScreen=()=>{
  
    if(isSuperAdmin)
    { return (
      
        <div>In Dashboard</div>
      
     )}
    
  
}
  if(isSuperAdmin)
 { return (
   
     <AdminTemplate>
      {DasgboardScreen()}
     </AdminTemplate>
   
  )}
 
}

export default Dashboard