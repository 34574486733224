import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import Mail from '@mui/icons-material/Mail';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';
import { updateCourseData } from '../Store/CourseData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate  } from "react-router-dom";
import { useCreateExistingStudentMutation, useCreateStudentMutation, useDeleteEnrolledStudentMutation, useGetAllEnrolledStudentsQuery, useGetAllStudentsQuery, useUpdateExistingStudentMutation, useUpdateStudentMutation } from '../Services/modules/liveCourses'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetStudentByUsernameMutation } from '../Services/modules/auth'
import { FlashlightOffRounded } from '@mui/icons-material'
function CreateExistingStudent() {
  const[isupdateRecord,setUpdateRecord]=useState(false);
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[studentId,setStudentId]=useState("")
  const navigate=useNavigate ()
  const dispatch = useDispatch();

  //const [createExistingStudent, { data, isSuccess, isLoading, isError, error }] = useCreateExistingStudentMutation();
  const [createStudent, { data, isSuccess, isLoading, isError, error }] = useCreateStudentMutation();

  const [updateExistinStudent, { data:updatedStudentData, isSuccess:updatedStudentSuccess, isLoading:updatedStudentLoading, isError:updateStudentError }] = useUpdateExistingStudentMutation();
  const [deleteEnrolledStudent, { data:deleteStudentData, isSuccess:deleteStudentSuccess, isLoading:deleteStudentLoading, isError:deleteStudentError }] = useDeleteEnrolledStudentMutation();

  // const { 
  //   data: students= [],  
  //   isLoading:loading=true,
  //   isSuccess:getAllStudentsSuccess=false
  //  } = useGetAllEnrolledStudentsQuery();
  const { 
    data: students= [],  
    isLoading:loading=true,
    isSuccess:getAllStudentsSuccess=false
   } = useGetAllStudentsQuery();
   const [GetStudentByUsername, { data:studentDataByUsername, isSuccess:studentDataByUsernameSuccess, isLoading:studentDataByUsernameLoading, isError:studentDataByUsernameError }] = useGetStudentByUsernameMutation();

   const columns = [ 
   
   
    { 
      key: "student_name", 
      title: "Student Name", 
      render: (_, record) => `${record.student_first_name} ${record.student_last_name}`,
     // dataIndex: "first_name", 
      },
  
    { 
    key: "course_name", 
    title: "Course Name", 
    dataIndex: "course_name", 
    }, 
    { 
      key: "course_duration", 
      title: "Duration", 
      dataIndex: "course_duration", 
      }, 
      { 
        key: "country", 
        title: "Country", 
        dataIndex: "country", 
        }, 
        { 
            key: "course_joining_date", 
            title: "Start Date", 
            dataIndex: "course_joining_date", 
            }, 
        { 
            key: "course_ending_date", 
            title: "End Date", 
            dataIndex: "course_ending_date", 
            }, 
        
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
               
          {/* <a onClick={()=>createSchoolProfile(record)} style={{color: "blue"}}>Profile </a>  */}
          <a onClick={()=>editStudent(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
          <a onClick={()=>deleteStudentConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>
        
        </Space>
      ),
    },
   
    ] 
    const editStudent = (record) => {
        // Populate input fields for editing
      
        setStudent({
            student_id:record._id,
          admission_type: record.admission_type,
          student_photo: record.student_photo,
          student_first_name: record.student_first_name,
      student_middle_name: record.student_middle_name,       

          student_last_name: record.student_last_name,
          mobile_number: record.mobile_number,
          country_code: record.country_code,
          country: record.country,
          college_Name: record.college_Name,
          address: record.address,
          state: record.state,
          DOB: record.DOB,
          course_id: record.course_id,
          course_name: record.course_name,
          course_fees: record.course_fees,
          admission_status: record.admission_status,
          payment_status: record.payment_status,
          username:record.username,
          course_joining_date:record.course_joining_date,
          course_duration:record.course_duration,
          is_local_student:record.is_local_student
        });
    
        // Update the state variable that indicates editing mode
        setUpdateRecord(true);
      };
      const deleteStudentConfirmAlert=(record)=>{
        //dispatch(clearStaffData())
        alert(record._id)
        setStudentId(record._id)
        setDeleteDialogOpen(true)
       
    
       }
    const deleteStudent=()=>{
      deleteEnrolledStudent({student_id:studentId})
    setDeleteDialogOpen(false)

    }
    const closeConfirmAlert=()=>{
    
        setDeleteDialogOpen(false)
    
       }
    const live_courses = [
        { key: "English communication and confidence Therapy", value: "English communication and confidence Therapy" },
        { key: "Leadership DNA", value: "Leadership DNA" },   
        { key: "Advanced communication skills for leaders", value: "Advanced communication skills for leaders" },      

        
      ];
      const admission_status = [
        { key: "pending", value: "pending" },   
        { key: "taken", value: "taken" },         
        
      ];
    const[Student,setStudent]=useState({
        student_id:"",
        admission_type:"",//new,old
        student_photo:"",
        student_first_name:"",
        student_last_name:"",
        student_middle_name:"",
        mobile_number:"",
        country_code:"",
        country:"",
        college_Name:"",
        address:"",
        state:"",
        DOB:"",
        course_id:"",
        course_name:"",
        course_fees:"",
        course_duration:"",
        admission_status:"",
        payment_status:"", 
        course_joining_date:"",        
        username:"" ,
        //is_local_student:FlashlightOffRounded     
        is_local_student:false     

      })  
      const formatDateString = (inputDate) => {
        // Remove any existing slashes from the input
        const dateWithoutSlashes = inputDate.replace(/\//g, '');
    
        // Restrict the input to a maximum length of 8 characters
        const maxLengthInput = dateWithoutSlashes.slice(0, 8);
    
        // Only allow numbers
        const validInput = maxLengthInput.replace(/\D/g, '');
    
        // Split the input into day, month, and year parts
        const day = validInput.slice(0, 2);
        const month = validInput.slice(2, 4);
        const year = validInput.slice(4, 8);
    
        // Construct the formatted date with slashes at the correct positions
        let formattedDate = '';
        if (day.length > 0) {
          formattedDate += day;
          if (day.length === 2) {
            formattedDate += '/';
          }
        }
        if (month.length > 0) {
          formattedDate += month;
          if (month.length === 2) {
            formattedDate += '/';
          }
        }
        if (year.length > 0) {
          formattedDate += year;
        }
    
        return formattedDate;
      };
      const handleKeyDown = (e) => {
   
        if (e.key === 'Backspace') {
          var value=e.target.value
         const formattedDate = value.replace(/\//g, '');
         // const formattedDate = Staff.birthdate.replace(/\//g, '');
          setStudent({
            ...Student,
            [e.target.name]: formattedDate
           // birthdate: formattedDate
          });
         
        }
      };
      function handleDate(e) {
   
        var value=e.target.value
        const formattedDate = formatDateString(value);
       
        setStudent({
          ...Student,
          [e.target.name]: formattedDate
        });
       // validateDate(formattedDate);
      }
      const handleLocalStudentChange = (e) => {
      //  setIsLocalStudent(e.target.checked);
      console.log("In checkbox change",e.target.name)
      console.log("In checkbox change value",e.target.checked)

        setStudent({
          ...Student,
          [e.target.name]: e.target.checked
        });
      };
    function handleChange(e) {
        var value = "";
      
        value=e.target.value
       
        setStudent({
          ...Student,
          [e.target.name]: value
        });
      }
      useEffect(() => {    
        if (isSuccess) {
            console.log("InSuccess",data);          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            toast.success(data.message)
            setStudent({
                admission_type: "",
                student_photo: "",
                student_name: "",
                mobile_number: "",
                country_code: "",
                country: "",
                college_Name: "",
                address: "",
                state: "",
                DOB: "",
                course_id: "",
                course_name: "",
                course_fees: "",
                course_duration:"",
                admission_status: "",
                payment_status: "",
                course_joining_date:"",
                username:"",
                is_local_student:false
              });
           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
    useEffect(() => {    
        if (updatedStudentSuccess) {
            console.log("InSuccess",updatedStudentData); 

            if(updatedStudentData.error==true)
            {
              toast.error(updatedStudentData.message)
              return 
            }
            toast.success(updatedStudentData.message)
           
            setStudent({
                student_id:"",
                admission_type: "",
                student_photo: "",
                student_name: "",
                mobile_number: "",
                country_code: "",
                country: "",
                college_Name: "",
                address: "",
                state: "",
                DOB: "",
                course_id: "",
                course_name: "",
                course_fees: "",
                course_duration:"",
                admission_status: "",
                payment_status: "",
                course_joining_date:"",
                username:""

              });
              setUpdateRecord(false)
        }
        if (isError) {
            alert("In error")
            console.log(error);
            setUpdateRecord(false)
        }
    }, [updatedStudentLoading]);
    useEffect(() => {    
        if (deleteStudentSuccess) {
            console.log("InSuccess",deleteStudentData); 

            if(deleteStudentData.error==true)
            {
              toast.error(deleteStudentData.message)
              return 
            }
            toast.success(deleteStudentData.message)
           
            setStudentId("")
              setUpdateRecord(false)
        }
        if (isError) {
            alert("In error")
            console.log(error);
            setStudentId("")
            setUpdateRecord(false)
        }
    }, [deleteStudentLoading]);
    useEffect(() => {    
      if (studentDataByUsernameSuccess) {
          console.log("InSuccess get student details",studentDataByUsername);          
          if(studentDataByUsername.error==true)
          {
            toast.error(studentDataByUsername.message)
            return 
          }
          toast.success(studentDataByUsername.message)
          setStudent({
            ...Student,
             // admission_type: "",
             // student_photo: "",
              student_first_name: studentDataByUsername.data.first_name,
              student_last_name: studentDataByUsername.data.last_name,
              student_middle_name:studentDataByUsername.data.middle_name,
              mobile_number: studentDataByUsername.data.mobile_number,
              country_code: studentDataByUsername.data.country_code,
              country: studentDataByUsername.data.country,
              username:studentDataByUsername.data.username,
              
              // college_Name: "",
              // address: "",
              // state: "",
              // DOB: "",
              // course_id: "",
              // course_name: "",
              // course_fees: "",
              // course_duration:"",
              // admission_status: "",
              // payment_status: "",
              // course_joining_date:"",
              // username:""
            });
         
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [studentDataByUsername]);
    useEffect(() => {   
     
      if(getAllStudentsSuccess)
      {
        console.log("students all check",students)
        }
      
    }, [getAllStudentsSuccess]);

    useEffect(() => {
      // Check if the admission_status changes to "pending"
      if (Student.admission_status === "pending") {
        // If it's "pending," set the course_joining_date to an empty string
        setStudent({
          ...Student,
          course_joining_date: "",
        });
      }
    }, [Student.admission_status]);
   
    function onSubmit (){    
        console.log("In on submit",Student)
        if(isupdateRecord)
        {
        // var checkvalidate=  validate()
       //  if(checkvalidate)
        // {
          if(Student.course_joining_date!=="")
          {
            if(Student.course_duration=="")
            {
             return toast.error("Please enter course duration")
            }
          }
            updateExistinStudent(Student)
         //}
        }
        else
        {
          //var checkvalidate=  validate()
        //  if(checkvalidate)
         // {
          if(Student.course_joining_date!=="")
          {
            if(Student.course_duration=="")
            {
             return toast.error("Please enter course duration")
            }
          }
            createStudent(Student)
           
         // }
        }
       
        
     // }  
    
     }
     const getStudentByUsername=()=>{
      GetStudentByUsername({username:Student.username})
     }
    const CreateExistingStudentScreen=()=>{
        return(
            <Container alignItems="center" sx={{mt:5}}>
                 <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteStudent} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                            <TextBox             
             label={"Student Username"}
             id={"username"}
             name={"username"}                                    
            value={Student.username}  
            autoFocus={true}                         

            isSelect={false}  
            onChange={handleChange}

            />
            <SubmitButton 
              caption={"Get Details"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>getStudentByUsername()}
              />
             
                              <TextBox             
             label={"Student First Name"}
             id={"student_first_name"}
             name={"student_first_name"}
             autoFocus={false}                         
            value={Student.student_first_name}  
            isSelect={false}      
            onChange={handleChange}

            />
              <TextBox             
             label={"Student Middle Name"}
             id={"student_middle_name"}
             name={"student_middle_name"}
             autoFocus={false}                         
            value={Student.student_middle_name}  
            isSelect={false}      
            onChange={handleChange}

            />

             <TextBox             
             label={"Student Last Name"}
             id={"student_first_name"}
             name={"student_first_name"}
             autoFocus={false}                         
            value={Student.student_last_name}  
            isSelect={false}      
            onChange={handleChange}

            />
            <TextBox             
             label={"Country"}
             id={"country"}
             name={"country"}                                    
            value={Student.country}  
            isSelect={false}  
            onChange={handleChange}

            />
              {/* <TextBox             
             label={"Country Code"}
             id={"country_code"}
             name={"country_code"}                                    
            value={Student.country_code}  
            isSelect={false}  
            onChange={handleChange}

            /> */}
             <TextBox             
             label={"Mobile Number"}
             id={"mobile_number"}
             name={"mobile_number"}                                    
            value={Student.mobile_number}  
            isSelect={false}  
            onChange={handleChange}

            />
           
              <TextBox
               values={live_courses}
               label={"Course Name*"}
               id={"course_name"}
               name={"course_name"}
              isSelect={true}
              value={Student.course_name}  
              onChange={handleChange}
              />
               <TextBox             
             label={"Course Fees"}
             id={"course_fees"}
             name={"course_fees"}                                    
            value={Student.course_fees}  
            isSelect={false}  
            onChange={handleChange}
            />
  <TextBox
               values={admission_status}
               label={"Admission Status*"}
               id={"admission_status"}
               name={"admission_status"}
              isSelect={true}
              value={Student.admission_status}  
              onChange={handleChange}
              />
             <TextBox 
            id={"course_joining_date"} 
            label={"Joining Date*"} 
            name={"course_joining_date"} 
            autoFocus={false}
            value={Student.admission_status=="taken"?Student.course_joining_date:""}
            onChange={handleDate}
            onKeyPress={handleKeyDown}
            disabled={Student.admission_status === "pending"}
            />
             <TextBox             
             label={"Course Duration"}
             id={"course_duration"}
             name={"course_duration"}                                    
            value={Student.course_duration}  
            isSelect={false}  
            onChange={handleChange}
            />
            <label>
  Local Student
  <input
    type="checkbox"
    name={"is_local_student"}
    checked={Student.is_local_student}
    onChange={handleLocalStudentChange}
  />
</label>

            <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
              
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={!loading? students.allStudents:[]}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
        )
    }
  return (
   <AdminTemplate>
    {CreateExistingStudentScreen()}
   </AdminTemplate>
  )
}

export default CreateExistingStudent