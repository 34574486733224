import { Grid,DialogTitle,Typography, Box, FormLabel, Checkbox } from '@mui/material'
import React,{ useState,useEffect } from 'react'
import SubmitButton from './InputFields/SubmitButton'
import TextBox from './InputFields/TextBox'
import { useSelector,useDispatch } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckBox } from '@mui/icons-material'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useCreateCoursePackageMutation } from '../Services/modules/course'

function CoursePackageForm() {
  const course_id = useSelector(state => state.courseData.course_data._id);
  let course_name=useSelector(state =>state.courseData.course_data.course_name)
 const[packageName,setPackageName]=useState("")
 const[packagePrice,setPackagePrice]=useState("")
const[RecordedVideoSelect,setRecordedVideoSelect]=useState(false)
const[LiveSessionSelect,setLiveSessionSelect]=useState(false)
const[RecordedLiveSessionSelect,setRecordedLiveSessionSelect]=useState(false)
const[PdfNotes,setPdfNotes]=useState(false)

const[mockInterview,setMockInterview]=useState(false)
const [createCoursePackage, 
  { data, 
    isSuccess, 
    isLoading, 
    isError, 
    error }] = useCreateCoursePackageMutation();
    useEffect(() => {    
      if (isSuccess) {
          console.log("InSuccess",data);          
          if(data.error==true)
          {
            toast.error(data.message)
            return 
          }
          toast.success(data.message)
         
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [isLoading]);

    const coursePackageFormScreen=()=>{
      const onSubmit=()=>{
        console.log("In submit")
        const payload={
          course_id:course_id,
          package_name:packageName,
          package_price:packagePrice,
          is_pdf_notes:PdfNotes,
          is_recorded_videos:RecordedVideoSelect,
          is_upcoming_live:LiveSessionSelect,
          is_recorded_live:RecordedLiveSessionSelect,
          is_mock_interview:mockInterview

        }
        createCoursePackage(payload)
      }
           
  
        return (
            
            <Box sx={{width: "auto", mt: 1 }}>
     <ToastContainer />
     <Typography component="h1" variant="h6">
                Create Package For The Course - {course_name}
            </Typography>
            <TextBox 
            id={"package_name"} 
            label={"Package Name*"} 
            name={"package_name"} 
            autoFocus={false}
             value={packageName}
             onChange={e=>{setPackageName(e.target.value)}}
            />
             <TextBox 
            id={"package_price"} 
            label={"Package Price*"} 
            name={"package_price"} 
            autoFocus={false}
            value={packagePrice}
            onChange={e=>{setPackagePrice(e.target.value)}}
           
            />
           
           <FormGroup>
      <FormControlLabel 
      control={
      <Checkbox 
      checked={RecordedVideoSelect} 
      onClick={e=>
        {
        console.log("e.target",e);        
          setRecordedVideoSelect(e.target.checked)       
      }
      } 
      />} 
      label="Recorded Videos" />
      <FormControlLabel 
      control={
      <Checkbox 
      checked={LiveSessionSelect} 
      onClick={e=>
        {
        console.log("e.target",e);        
        setLiveSessionSelect(e.target.checked)       
      }
      } 
      />
      } label="Live Sessions" />
       <FormControlLabel 
      control={
      <Checkbox 
      checked={mockInterview} 
      onClick={e=>
        {
        console.log("e.target",e);        
        setMockInterview(e.target.checked)       
      }
      } 
      />} label="Mock interview with personal report" />
      <FormControlLabel 
      control={
      <Checkbox 
      checked={RecordedLiveSessionSelect} 
      onClick={e=>
        {
        console.log("e.target",e);        
        setRecordedLiveSessionSelect(e.target.checked)       
      }
      } 
      />} label="Live Session Recordings" />
      <FormControlLabel 
      control={
      <Checkbox 
      checked={PdfNotes} 
      onClick={e=>
        {
        console.log("e.target",e);        
        setPdfNotes(e.target.checked)       
      }
      } 
      />
      } label="PDF Notes" />

    </FormGroup>
           
            <SubmitButton 
            caption={"Save"}            
            onClick={()=>onSubmit()}
            />
          
          </Box>
          )
    }

    return(
        coursePackageFormScreen()
    )
 
}

export default CoursePackageForm