import { api } from '../../api'

// import createTestimonial from './createTestimonial'
// import getTestimonials from './getTestimonials'
// import deleteTestimonial from './deleteTestimonial'
// import updateTestimonial from './updateTestimonial'
import createBlog from './createBlog'
import deleteBlog from './deleteBlog'
import getBlogs from './getBlogs'
import updateBlog from './updateBlog'

export const blogApi = api.injectEndpoints({
    endpoints: build => ({
       GetAllBlogs: getBlogs(build),         
        CreateBlog:createBlog(build),
        UpdateBlog:updateBlog(build),
        DeleteBlog:deleteBlog(build),
        // UpdateTestimonial:updateTestimonial(build),
        // DeleteTestimonial:deleteTestimonial(build)        
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {useCreateBlogMutation,useUpdateBlogMutation,useGetAllBlogsQuery,useDeleteBlogMutation} = blogApi
