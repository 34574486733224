import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useDeletCourseMutation,useGetAllCoursesQuery, useUpdateCourseMutation } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import Mail from '@mui/icons-material/Mail';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';
import { updateCourseData } from '../Store/CourseData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate  } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { allRecordedVideosData } from '../Store/AllRecordedVideos'

function Course() {
  const[isupdateRecord,setUpdateRecord]=useState(false);
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
   

  const navigate=useNavigate ()
  const dispatch = useDispatch();

  const [createCourse, { data, isSuccess, isLoading, isError, error }] = useCreateCourseMutation();
  const [updateCourse, { data:editCourseData, isSuccess:updateCourseSuccess, isLoading:updateCourseLoading, isError:updateCourseError}] = useUpdateCourseMutation();
  const [deleteCourse, { data:deleteCourseData, isSuccess:deleteCourseSuccess, isLoading:deleteCourseLoading, isError:deleteCourseError}] = useDeletCourseMutation();

 const[courseId,setCourseId]=useState("")
 const[allRecordedVideosLocal,setAllRecordedVideos]=useState([])
  const { 
    data: courses= [],  
    isLoading:loading=true,
    isSuccess:getAllCoursesSuccess=false
   } = useGetAllCoursesQuery();
   const columns = [ 
   
    { 
    key: "course_name", 
    title: "Course Name", 
    dataIndex: "course_name", 
    }, 
  
    { 
    key: "course_description", 
    title: "Description", 
    dataIndex: "course_description", 
    }, 
    { 
      key: "course_community", 
      title: "Community", 
      dataIndex: "course_community", 
      }, 
      { 
        key: "course_privacy", 
        title: "Privacy", 
        dataIndex: "course_privacy", 
        }, 
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>addCourseContent(record)} style={{color:"green"}}>
          <AddBoxIcon style={{fontSize:'medium'}}/> 
          </a>    
          <a onClick={()=>addCoursePackage(record)} style={{color:"green"}}>
            <Tooltip title="Create Packages">
          <CurrencyRupeeIcon style={{fontSize:'medium'}}/> 
          </Tooltip>
          </a>        
          {/* <a onClick={()=>createSchoolProfile(record)} style={{color: "blue"}}>Profile </a>  */}
          <a onClick={()=>editCourse(record)} style={{color: "blue"}}>
            <EditIcon style={{fontSize:'medium'}}/> </a>
          <a onClick={()=>deleteCourseConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>
        
        </Space>
      ),
    },
   
    ] 
    const editCourse=(record)=>{
      setCourse({
        course_id:record._id,
        course_name:record.course_name,
        course_price:record.course_price,
        course_description:record.course_description,
        course_community:record.course_community,
        course_privacy:record.course_privacy 
      });
      setUpdateRecord(true)
    }
  
    const deleteCourseConfirmAlert=(record)=>{
      //dispatch(clearStaffData())
      setCourseId(record._id)
      setDeleteDialogOpen(true)
    
     }
  const deleteCourseRecord=()=>{
      deleteCourse({course_id:courseId})
      setDeleteDialogOpen(false)
  }
  const closeConfirmAlert=()=>{
  
      setDeleteDialogOpen(false)
  
     }
    const[Course,setCourse]=useState({
      course_id:"",
        course_name:"",
        course_price:"",
        course_description:"",
        course_community:"",
        course_privacy:""      
      })  
      const community = [
        { key: "live_classes", value: "Live Classes" },
        { key: "live_classes_local", value: "Live Classes Local" },
        { key: "recorded_courses", value: "Recorded Courses" },      
        { key: "both", value: "Both" },
       
      ];
      const privacy = [
        { key: "public", value: "public" },
        { key: "private", value: "private" },      
        
      ];
    function handleChange(e) {
        var value = "";
      
        value=e.target.value
      
        setCourse({
          ...Course,
          [e.target.name]: value
        });
      }
      useEffect(() => {    
        if (isSuccess) {
            console.log("InSuccess",data);          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            toast.success(data.message)
            setCourse({
              course_name:"",
              course_price:"",
              course_description:"",
              course_community:"",
              course_privacy:"" 
            });
           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
    useEffect(() => {    
      if (updateCourseSuccess) {
          console.log("InSuccess",data);          
          if(editCourseData.error==true)
          {
            toast.error(data.message)
            return 
          }
          toast.success(editCourseData.message)
          setCourse({
            course_id:"",
            course_name:"",
            course_price:"",
            course_description:"",
            course_community:"",
            course_privacy:"" 
          });
          setUpdateRecord(false)
         
      }
      if (updateCourseError) {
          alert("In error")
          console.log(error);
      }
  }, [updateCourseLoading]);
    // useEffect(() => {   
     
    //   if(getAllCoursesSuccess)
    //   {
        
    //     console.log("Courses",courses)
    //     courses.allCourses.map((data,index)=>{
    //      // console.log("All Recorded videos",data)
    //       data.course_recorded_videos_data.map((recorded_videos_data,index1)=>{
    //         //console.log("All Recorded videos1",recorded_videos_data)
    //         recorded_videos_data.lessons.map((video_lessons,index3)=>{
    //           console.log("All Recorded videos3",video_lessons)
    //           setAllRecordedVideos(video_lessons.lesson_name)
    //           dispatch(allRecordedVideosData(video_lessons.lesson_name))
    //         })
    //       })
    //     })
    //     }
      
    // }, [getAllCoursesSuccess]);

    useEffect(() => {
      if (getAllCoursesSuccess) {
        const allLessonNames = [];
    
        courses.allCourses.forEach((data) => {
          data.course_recorded_videos_data.forEach((recorded_videos_data) => {
            recorded_videos_data.lessons.forEach((video_lessons) => {
              console.log("video_lessons",video_lessons)
              const { _id,lesson_name,media_url,video_name,media_type,media_url_youtube } = video_lessons;
              allLessonNames.push({video_id:_id,video_name:video_name,lesson_name:lesson_name,media_url:media_url,media_type:media_type,media_url_youtube:media_url_youtube});
            });
          });
        });
    
        dispatch(allRecordedVideosData({ data: allLessonNames }));
      }
    }, [getAllCoursesSuccess]);
    

    const addCourseContent=(courseDetails)=>{
      dispatch(updateCourseData({ data: courseDetails }))
    //  navigate('/coursecontent')
      navigate({
        pathname: '/coursecontent',
        search: `?id=${courseDetails._id}`,

        //search: '?sort=date&order=newest',
      });

    }
    const addCoursePackage=(courseDetails)=>{
      dispatch(updateCourseData({ data: courseDetails }))
      navigate('/coursepackage')

    }
    function onSubmit (){    
        console.log("In on submit",Course)
        if(isupdateRecord)
        {
         // var checkvalidate=  validate()
         // if(checkvalidate)
         // {
          updateCourse(Course)
         // }
        }
        else
        {
          //var checkvalidate=  validate()
        //  if(checkvalidate)
         // {
            createCourse(Course)
           
         // }
        }
       
        
     // }  
    
     }
    const courseScreen=()=>{
        return(
            <Container alignItems="center" sx={{mt:5}}>
       <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteCourseRecord} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                              <TextBox             
             label={"Course Name"}
             id={"course_name"}
             name={"course_name"}
             autoFocus={true}                         
            value={Course.course_name}  
            isSelect={false}      
            onChange={handleChange}

            />
            
              <TextBox             
             label={"Course Description"}
             id={"course_description"}
             name={"course_description"}                                    
            value={Course.course_description}  
            isSelect={false}  
            onChange={handleChange}

            />
              <TextBox
               values={community}
               label={"For Community*"}
               id={"course_community"}
               name={"course_community"}
              isSelect={true}
              value={Course.course_community}  
              onChange={handleChange}
              />
                <TextBox
               values={privacy}
               label={"Course Privacy*"}
               id={"course_privacy"}
               name={"course_privacy"}
              isSelect={true}
              value={Course.course_privacy}  
              onChange={handleChange}
              />
            <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={!loading? courses.allCourses:[]}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
        )
    }
  return (
   <AdminTemplate>
    {courseScreen()}
   </AdminTemplate>
  )
}

export default Course