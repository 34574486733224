import { api } from '../../api'
import updateRecordedLive from './updateRecordedLive'

import createCourse from './createCourse'
import createCoursePackage from './createCoursePackage'
import getCourseById from './getCourseById'
import getCourses from './getCourses'
import updateCourseLesson from './updateCourseLesson'
import updateCourseSection from './updateCourseSection'
import uploadThumbnailImage from './uploadThumbnailImage'
import getPackageById from './getPackageById'
import updateCourseIntroVideo from './updateCourseIntroVideo'
import updateCourse from './updateCourse'
import deleteCourse from './deleteCourse'
import editSectionName from './editSectionName'
import deleteSection from './deleteSection'
import editLessonName from './editLessonName'
import deleteLesson from './deleteLesson'
import uploadIntroVideo from './uploadIntroVideo'
import getAllRecordedVideos from './getAllRecordedVideos'
import updateLessonExistingMedia from './updateLessonExistingMedia'
import createFaq from './createFaq'
import getFaqs from './getFaqs'
import deleteFaq from './deleteFaq'
import editFaq from './editFaq'

export const courseApi = api.injectEndpoints({
    endpoints: build => ({
        CreateCourse: createCourse(build) ,  
        UpdateCourse:updateCourse(build),
        DeletCourse:deleteCourse(build),
        CreateCoursePackage: createCoursePackage(build) ,
        GetAllCourses:getCourses(build),  
        GetCourseById:getCourseById(build) ,
        GetPackageById:getPackageById(build) ,
        UpdateCourseSection:updateCourseSection(build),
        EditSectionName:editSectionName(build),
        DeleteSection:deleteSection(build),
        EditLessonName:editLessonName(build),
        DeleteLesson:deleteLesson(build),
        UpdateCourseLesson:updateCourseLesson(build),
        UpdateRecordedLive:updateRecordedLive(build),
        UpdateCourseIntroVideo:updateCourseIntroVideo(build),
        UploadThumbnailImage:uploadThumbnailImage(build),
        UploadIntroVideo:uploadIntroVideo(build),
        GetAllRecordedVideos:getAllRecordedVideos(build),
        updateLessonExistingMedia:updateLessonExistingMedia(build),
        CreateFaq:createFaq(build),
        GetFaq:getFaqs(build),
        DeleteFaq:deleteFaq(build),
        EditFaq:editFaq(build)
    }),
    overrideExisting: true,
})
export const {
    useUpdateCourseIntroVideoMutation,
    useUpdateRecordedLiveMutation,
    useUpdateCourseLessonMutation,
     useUpdateCourseSectionMutation,
     useEditSectionNameMutation,
useDeleteSectionMutation,
useEditLessonNameMutation,
useDeleteLessonMutation,
      useCreateCourseMutation,
      useUpdateCourseMutation,
      useDeletCourseMutation,
      useCreateCoursePackageMutation,
      useGetAllCoursesQuery,
      useGetCourseByIdQuery,
      useGetPackageByIdQuery,
      useUploadThumbnailImageMutation,
      useUploadIntroVideoMutation,
      useGetAllRecordedVideosQuery,
      useUpdateLessonExistingMediaMutation,
      useCreateFaqMutation,
      useGetFaqQuery,
      useDeleteFaqMutation,
      useEditFaqMutation
     } = courseApi
