export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/course/updaterecordedlive',
                method: 'POST',
                body: payload,
               
            };
        },
       invalidatesTags: ['courseById'],
    })
