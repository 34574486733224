import React, { useEffect,useState } from 'react'
import { useDeleteShortMutation, useGetAllShortsQuery, useUploadShortMutation } from '../Services/modules/shorts';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, Grid, Paper,Container, Tooltip } from '@mui/material'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import AdminTemplate from '../Components/Template/AdminTemplate';
import { Config } from '../Config';


function Shorts() {
    const[allShorts,setAllShorts]=useState([])
    const [file, setFile] = useState([]);
    const [thumbnail_image, setThumbnailImage] = useState([]);

    const[shortDetails,setShortDetails]=useState()
    const[short_caption,setShortCaption]=useState("")
    const[isupdateRecord,setUpdateRecord]=useState(false);
    const[showDialogDelete,setShowDialogDelete]=useState(false)
    const[showDialogDetails,setShowDialogDetails]=useState(false)
    const[viewShortDetails,setViewShortDetails]=useState({})
    const [is_update, setIsUpdate] = useState(false);
    const[totalCount,setTotalCount]=useState(0)
    
    const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10 }); // Initial pagination info

    const[short_id,setShortId]=useState("")
  const [UploadShort, { data, isSuccess, isLoading, isError, error }] = useUploadShortMutation();
  const [DeletShort, { data:dataDelete, isSuccess:isSuccessDelete, isLoading:isLoadingDelete, isError:isErrorDelete, error:errorDelete }] = useDeleteShortMutation();
  const { 
    data: dataGet= [],  
    isLoading:isLoadingGet=true,
    isSuccess:isSuccessGet=false
   } = useGetAllShortsQuery( 
 {  pageIndex: pageInfo.current - 1, // Adjust pageIndex for zero-based indexing
   pageSize: pageInfo.pageSize}
  );

   useEffect(()=>{
    console.log("dataGet",dataGet)
    if(isSuccessGet)
        {
            setAllShorts(dataGet.data)
            setTotalCount(dataGet.total)
        }
   },[dataGet])
   const handleFileChange = (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    const selectedFile = event.target.files[0];   
    if (selectedFile && selectedFile.type === 'video/mp4') {
      setFile(selectedFile);
    } else {
      alert('Please select a PDF file.');
    }
  };
  const handleThumbnailFileChange = (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    const selectedFile = event.target.files[0];   
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setThumbnailImage(selectedFile);
    } else {
      alert('Please select a PDF file.');
    }
  };
  const closeDialog=()=>{
  
    setShowDialogDelete(false)

   }
   const closeDetailDialog=()=>{
  
    setShowDialogDetails(false)

   }
   const openDeleteAlertDialog=(record)=>{
setShortId(record._id)
setShowDialogDelete(true)
   }
   const deleteShort=()=>{
    DeletShort({short_id})
   }
   useEffect(()=>{
    setShowDialogDelete(false)
if(isSuccessDelete)
{
  toast.success("Short Deleted successfully")
 
}
},[dataDelete])

const showDetails=(record)=>{
    setViewShortDetails(record)
    setShowDialogDetails(true);
   }

const columns = [   
    
    { 
    key: "caption", 
    title: "Type", 
    dataIndex: "caption", 
    }, 
   
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">  
        <a onClick={()=>showDetails(record)} style={{color: "blue"}}><RemoveRedEyeIcon style={{fontSize:'medium'}}/> </a> 
        <a onClick={()=>openDeleteAlertDialog(record)} style={{color:"green"}}>
          
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
        
         
        </Space>
      ),
    },
    
    ] 

    function onSubmit (){    
        console.log("notes file",file)
        const form = new FormData(); 
        form.append('video_caption',short_caption)      
        form.append('video_file',file); 
        form.append('thumbnail_image',thumbnail_image); 

  UploadShort(form)        


     }
     const handlePageChange = (pagination) => {
      setPageInfo({
          current: pagination.current,
          pageSize: pagination.pageSize,
      });
  };
    const uploadShortScreen=()=>{

        return(
            <>
            <ToastContainer/>
             <Dialog
        open={showDialogDelete}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button onClick={deleteShort} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialogDetails}
        onClose={closeDetailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Shorts Details"}
        </DialogTitle>
        <DialogContent>
       
        <Grid item xs={12} md={12} lg={12}>
         Short Caption: {viewShortDetails.caption}
         </Grid>
        
         <Grid item xs={12} md={12} lg={12}>
         <video controls style={{ maxWidth: '100%', maxHeight: '300px' }}>
         poster={`${Config.API_FILE}/ShortVideos/${viewShortDetails.thumbnail_image}`}
          <source 
          src={`${Config.API_FILE}/ShortVideos/${viewShortDetails.video_file}`} type="video/mp4"></source>

          Your browser does not support the video tag.
        </video> 
         </Grid>
         
        
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetailDialog}>Close</Button>
        
        </DialogActions>
      </Dialog>
      <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >

       
        <Grid item xs={12} md={5} lg={5}>
        <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
  <TextBox             
             label={"Short Caption"}
             id={"short_caption"}
             name={"short_caption"}           
             autoFocus={true}                         
            value={short_caption}  
            isSelect={false}      
            onChange={(e)=>setShortCaption(e.target.value)}
            />     

<div>    
<label htmlFor="upload-video" style={{ marginRight: '10px', cursor: 'pointer', color: 'blue' }}>
          Upload Short Video
        </label>
          <input
           label={"Upload Video"}
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />
        </div>
 

        <div style={{ marginTop: '20px' }}>
        <label htmlFor="upload-thumbnail" style={{ marginRight: '10px', cursor: 'pointer', color: 'blue' }}>
          Upload Image
        </label>       
          <input
            label={"Upload Video"}
            type="file"
            accept="image/*"
            onChange={handleThumbnailFileChange}
            required
            // style={{ display: 'none' }}
          />
        </div>

        
        <SubmitButton 
              caption={is_update?"Update":"Save"} 
             isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                            </Paper>
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allShorts}
                  totalCount={totalCount}
                  handleChange={handlePageChange}
                  />
                       
                         </Paper>
                  </Grid>
        </Grid>

            </>
        )
    }
  return (
    <AdminTemplate>{uploadShortScreen()}</AdminTemplate>
  )
}

export default Shorts