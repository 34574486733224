import React, { useState,useEffect } from 'react'
import {Box, Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";

import { Config } from '../Config'
import { useCreateBookMutation, useDeleteBookMutation, useGetAllBooksQuery } from '../Services/modules/books'
import { useCreateTestimonialMutation, useDeleteTestimonialMutation, useGetAllTestimonialsQuery, useUpdateTestimonialMutation } from '../Services/modules/testimonials'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import { useCreateBlogMutation, useDeleteBlogMutation, useGetAllBlogsQuery, useUpdateBlogMutation } from '../Services/modules/blog'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

function Blog() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
const{data:blogsData=[],isSuccess:isBlogsSuccess,isLoading:isBlogsLoading,error:isBlogsError}=useGetAllBlogsQuery()
  const [CreateBlog, { data, isSuccess, isLoading, isError, error }] = useCreateBlogMutation();
  const [UpdateBlog, { data:updateData, isSuccess:updateSuccess, isLoading:updateLoading, isError:updateIsError, error:updateError }] = useUpdateBlogMutation();

  const [DeleteBlog, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError}] = useDeleteBlogMutation();
 
  const [testimonial_id, setTestimonialId] = useState('');
  const [is_update, setIsUpdate] = useState(false);

  const[allBlogs,setAllBlogs]=useState([])

    const [blog_title, setBlogTitle] = useState('');
    const [blog_date, setBlogDate] = useState('');
   
    const [long_text, setLongText] = useState('');
   
    const [youtube_link, setYoutubeLink] = useState('');
    const [blog_id, setBlogId] = useState('');
    const[showDialogDelete,setShowDialogDelete]=useState(false)
    const[showDialogDetails,setShowDialogDetails]=useState(false)
    const[viewBlogDetails,setViewBlogDetails]=useState({})
const[short_text,setShortText]=useState('')
    useEffect(()=>{
        console.log("blogs data",blogsData)
      if(isBlogsSuccess)
      {
      
          setAllBlogs(blogsData.data)
      }
      },[blogsData])
   
useEffect(()=>{
 
if(isSuccess)
{
  toast.success("Blog created successfully")
 
}
},[data])
useEffect(()=>{
    setBlogTitle("")
    setBlogDate("")
 setLongText("")
 setShortText("")
 setYoutubeLink("")
 setBlogId("")
 
  if(updateSuccess)
  {
    toast.success("Blog updated successfully")
   
  }
  },[updateData])
useEffect(()=>{
    setShowDialogDelete(false)
if(deleteSuccess)
{
  toast.success("Blog Deleted successfully")
 
}
},[deleteData])
const columns = [   
    { 
        key: "blog_title", 
        title: "Title", 
        dataIndex: "blog_title", 
        },  
    { 
    key: "blog_date", 
    title: "Date", 
    dataIndex: "blog_date", 
    }, 
   
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">  
        <a onClick={()=>showDetails(record)} style={{color: "blue"}}><RemoveRedEyeIcon style={{fontSize:'medium'}}/> </a> 
        <a onClick={()=>deleteBlogConfirm(record)} style={{color:"green"}}>
          
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
          <a onClick={()=>editBlog(record)} style={{color:"green"}}>
          
          <EditIcon style={{fontSize:'medium'}}/> 
          </a>   
         
        </Space>
      ),
    },
    
    ] 
   
    const showDetails=(record)=>{
        setViewBlogDetails(record)
        setShowDialogDetails(true);
       }
    const deleteBlogConfirm=(record)=>{
        console.log("Notes id",record._id)
        setBlogId(record._id)
        setShowDialogDelete(true)
        }
        const editBlog=(record)=>{
          console.log("Notes id",record._id)
          setBlogId(record._id)
          setIsUpdate(true)
          setLongText(record.long_text)
        
          setBlogTitle(record.blog_title)
          setBlogDate(record.blog_date)
          setYoutubeLink(record.youtube_link)
         
          }
const deleteBlog=()=>{
console.log("Notes id",blog_id)
DeleteBlog({id:blog_id})
}
const closeConfirmAlert=()=>{
  
    setShowDialogDelete(false)

   }
   const closeDetailDialog=()=>{
  
    setShowDialogDetails(false)

   }
   
    
    function onSubmit (){    
  
        
        const form = new FormData(); 
        form.append('blog_title',blog_title)
        form.append('blog_date',blog_date);
        form.append('long_text',long_text);    
        form.append('youtube_link',youtube_link);   
      
        form.append("blog_id",blog_id)  

if(is_update)
{
  UpdateBlog({blog_id:blog_id,blog_title,blog_date,long_text,small_text:short_text,youtube_link})
}
else
{
  CreateBlog({blog_title,blog_date,long_text,small_text:short_text,youtube_link})        

}
     }
    const BlogScreen=()=>{
        return(
          <>
           <Dialog
        open={showDialogDelete}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteBlog} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialogDetails}
        onClose={closeDetailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Testimonials Details"}
        </DialogTitle>
        <DialogContent>
      
        <Grid item xs={12} md={12} lg={12}>
         Blog Title: {viewBlogDetails.blog_title}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Date: {viewBlogDetails.blog_date}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
        Blog: 
        <div dangerouslySetInnerHTML={{ __html: viewBlogDetails.short_text }} />
        
         </Grid>
        <Grid item xs={12} md={12} lg={12}>
        Blog: 
        <div dangerouslySetInnerHTML={{ __html: viewBlogDetails.long_text }} />
        
         </Grid>
       
        
         <Grid item xs={12} md={12} lg={12}>
         <iframe width="560" height="315" 
        src={`https://www.youtube.com/embed/${viewBlogDetails.youtube_link}`}                    
        frameborder="0" allowfullscreen></iframe>  
         </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetailDialog}>Close</Button>
        
        </DialogActions>
      </Dialog>
           <ToastContainer />
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div> 
        <TextBox             
             label={"Blog Title"}
             id={"blog_title"}
             name={"blog_title"}
             disabled={false}
             autoFocus={true}                         
            value={blog_title}  
            isSelect={false}      
            onChange={(e)=>setBlogTitle(e.target.value)}
            />    
             <TextBox             
             label={"Blog Date"}
             id={"blog_date"}
             name={"blog_date"}
             disabled={false}
             autoFocus={false}                         
            value={blog_date}  
            isSelect={false}      
            onChange={(e)=>setBlogDate(e.target.value)}
            />  
  <label>Short text</label>

             <ReactQuill
    className="mb-3"
    value={short_text}
   // onChange={(value) => setLongText({ ...long_text, long_text: value })}
   onChange={(value) => setShortText(value)}
   // onChange={(e) =>setLongText(e.target.value)}
  />  
  <label>Contineu reading text</label>
               <ReactQuill
    className="mb-3"
    value={long_text}
   // onChange={(value) => setLongText({ ...long_text, long_text: value })}
   onChange={(value) => setLongText(value)}
   // onChange={(e) =>setLongText(e.target.value)}
  />  
             
         
             <TextBox             
             label={"Video Link"}
             id={"youtube_link"}
             name={"youtube_link"}
             autoFocus={false}                         
            value={youtube_link}  
            isSelect={false}    
            disabled={false}  
            onChange={(e)=>setYoutubeLink(e.target.value)}
            />                                
        </div>
    
            <SubmitButton 
              caption={is_update?"Update":"Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allBlogs}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {BlogScreen()}
   </AdminTemplate>
  )
}

export default Blog