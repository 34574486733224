import { api } from '../../api'

import uploadShorts from './uploadShorts'
import getAllShorts from './getAllShorts'
import deleteShort from './deleteShort'

export const shortApi = api.injectEndpoints({
    endpoints: build => ({
        UploadShort: uploadShorts(build),         
        GetAllShorts:getAllShorts(build),
        DeleteShort:deleteShort(build)
      
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useUploadShortMutation, 
    useGetAllShortsQuery,
    useDeleteShortMutation,
   
} = shortApi
