// import React from 'react'
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };
// function ComponentModal({isOpen,setIsOpen,children,caption}) {
//     const closeModal=()=>{
//         setIsOpen(false)
//     }
//   return (
//     <div> 
//         <Modal
//         disableEnforceFocus 
//     open={isOpen}
//     onClose={()=>closeModal()}
//     aria-labelledby="modal-modal-title"
//     aria-describedby="modal-modal-description"
//   >
//     <Box sx={style}>
//       <Typography id="modal-modal-title" variant="h6" component="h2">
//        {caption}
//       </Typography>
//       <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//        {children}
//       </Typography>
//     </Box>
//   </Modal>
//   </div>
//   )
// }

// export default ComponentModal


import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh', // Set a maximum height for the Modal
  overflow: 'auto', // Enable overflow to make the content scrollable
};

function ComponentModal({ isOpen, setIsOpen, children, caption }) {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        disableEnforceFocus
        open={isOpen}
        onClose={() => closeModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {caption}
          </Typography>
          {/* Wrap the content inside a div with overflow styles */}
          <div style={{ overflow: 'auto', maxHeight: 'calc(80vh - 80px)' }}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {children}
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ComponentModal;