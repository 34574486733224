import { api } from '../../api'

import addYoutubeVideos from './addYoutubeVideos'
import getAllYoutubeVideos from './getAllYoutubeVideos'

export const youtubeVideosApi = api.injectEndpoints({
    endpoints: build => ({
        AddYoutubeVideos: addYoutubeVideos(build),
        GetAllYoutubeVideos:getAllYoutubeVideos(build)         
       
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddYoutubeVideosMutation, 
    useGetAllYoutubeVideosQuery
  
} = youtubeVideosApi
