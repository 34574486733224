import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../Services/api'
import userData from './UserData'
import courseData from './CourseData'
import allRecordedVideos from './AllRecordedVideos'
//import schoolData from './SchoolData'
//import staffData from './StaffData';
import storageSession from 'redux-persist/lib/storage/session';
//import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage:storageSession,
};

const reducers = combineReducers({  
    userData,
    courseData,
    allRecordedVideos,
   // schoolData,
   // staffData,
    api: api.reducer,
  })
  const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer ,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);