export default build =>
build.query({
    tagTypes: ['allFaqs'],
    keepUnusedDataFor: 1,
    query(payload) {
        console.log("payload",payload)            
        return {
            url: `/course/getfaq?course_id=${payload.course_id}`,
            method: 'GET',
            //body: payload,
        };
       
    },
   providesTags: ['allFaqs']
})
