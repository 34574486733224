import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";

import { Config } from '../Config'
import { useCreateBookMutation, useDeleteBookMutation, useGetAllBooksQuery } from '../Services/modules/books'
function Books() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
const{data:booksData=[],isSuccess:isBooksSuccess,isLoading:isBooksLoading,error:isnBooksError}=useGetAllBooksQuery()
  const [CreateBook, { data, isSuccess, isLoading, isError, error }] = useCreateBookMutation();
  const [DeleteBook, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError}] = useDeleteBookMutation();

   const columns = [    
    { 
    key: "book_name", 
    title: "Book Name", 
    dataIndex: "book_name", 
    }, 
    { 
        key: "book_price", 
        title: "Book Price", 
        dataIndex: "book_price", 
        }, 
    
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>deleteBook(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
         
        </Space>
      ),
    },
    
    ] 
    const [book_name, setBookName] = useState('');
    const [book_price, setBookPrice] = useState('');
    const [book_description, setBookDescription] = useState('');
  const [file, setFile] = useState([]);
   
    const[allBooks,setAllBooks]=useState([])
    useEffect(()=>{
      console.log("notes data",booksData)
    if(isBooksSuccess)
    {
    
      setAllBooks(booksData.data)
    }
    },[booksData])
useEffect(()=>{
 
if(isSuccess)
{
  toast.success("Book created successfully")
 
}
},[data])
useEffect(()=>{

if(deleteSuccess)
{
  toast.success("Books Deleted successfully")
 
}
},[deleteData])

const deleteBook=(record)=>{
console.log("Notes id",record._id)
DeleteBook({book_id:record._id})
}
   
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Check if the selected file is a PDF
        if (selectedFile && selectedFile.type.startsWith('image/')) {
          setFile(selectedFile);
        } else {
          alert('Please select a Image file.');
        }
      };
    
    function onSubmit (){    
        console.log("notes file",file)
        const form = new FormData(); 

        form.append('book_name',book_name);
        form.append('book_price',book_price);    
        form.append('book_description',book_description);    
        form.append('book_img_file',file); 

            CreateBook(form)        
     }
    const BooksScreen=()=>{
        return(
          <>
           <ToastContainer />
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div>         
          <TextBox             
             label={"Book Name"}
             id={"book_name"}
             name={"book_name"}
             autoFocus={true}                         
            value={book_name}  
            isSelect={false}      
            onChange={(e)=>setBookName(e.target.value)}
            />        
             <TextBox             
             label={"Book Price"}
             id={"book_price"}
             name={"book_price"}
             autoFocus={false}                         
            value={book_price}  
            isSelect={false}      
            onChange={(e)=>setBookPrice(e.target.value)}
            />     
              <TextBox             
             label={"Book Description"}
             id={"book_description"}
             name={"book_description"}
             autoFocus={false}                         
            value={book_description}  
            isSelect={false}      
            onChange={(e)=>setBookDescription(e.target.value)}
            />         
        </div>
        <div>
         
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />
        </div>
       
            <SubmitButton 
              caption={"Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allBooks}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {BooksScreen()}
   </AdminTemplate>
  )
}

export default Books