export default build =>
    build.query({
        tagTypes: ['allWords'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/otherinfo/getallwords?page_index=${payload.page_index}&page_size=${payload.page_size}`,                         
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allWords']
    })
