import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";
import { useCreateNotesMutation, useDeleteNotesMutation, useGetAllNotesQuery } from '../Services/modules/notes'
import { Config } from '../Config'
import { useGetAllUploadedPodcastsQuery, useUploadPodcastMutation } from '../Services/modules/podcast'
function PodcastMeditation() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);

 
  const [UploadPodcast, { data, isSuccess, isLoading, isError, error }] = useUploadPodcastMutation();
  const [DeleteNotes, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError}] = useDeleteNotesMutation();

  const { 
    data: uploadedPodcastsData= [],  
    isLoading:loading=true,
    isSuccess:getUploadedPodcastsSuccess=false
   } = useGetAllUploadedPodcastsQuery({type:'meditation'});
   const columns = [ 
   
    { 
    key: "caption", 
    title: "Caption", 
    dataIndex: "caption", 
    }, 
    { 
      key: "podcast_url", 
      title: "Podcast", 
      render: (_, record) => (
            <Space size="middle">   
            <audio
        controls  // This attribute adds built-in controls for play/pause, volume, etc.
        src={`${Config.API_FILE}/podcasts/${record.podcast_url}`} // Specify the path to your audio file here
        onPlay={() => setIsPlaying(true)}  // Callback when audio starts playing
        onPause={() => setIsPlaying(false)} // Callback when audio is paused
        onEnded={() => setIsPlaying(false)} // Callback when audio playback ends
      />                             
            </Space>
          ),
      }, 
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>deleteNotes(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
         
        </Space>
      ),
    },
    
    ] 
    const [podcastCaption, setPodcastCaption] = useState('');
    const [type, setType] = useState('meditation');
   
  const [file, setFile] = useState([]);
    const[isupdateRecord,setUpdateRecord]=useState(false);
    const[allPodcasts,setAllPodcasts]=useState([])
  
   
    useEffect(()=>{
      console.log("uploaded podcast data",uploadedPodcastsData)
    if(getUploadedPodcastsSuccess)
    {
    
        setAllPodcasts(uploadedPodcastsData.data)
    }
    },[uploadedPodcastsData])
useEffect(()=>{
 // console.log("notes data",notesData)
if(isSuccess)
{
  toast.success("Podcast uploaded successfully")
  //setAllNotes(notesData.data)
}
},[data])
useEffect(()=>{
 // console.log("notes data",notesData)
if(deleteSuccess)
{
  toast.success("Notes Deleted successfully")
 // setAllNotes(notesData.data)
}
},[deleteData])

const deleteNotes=(record)=>{
console.log("Notes id",record._id)
DeleteNotes({notes_id:record._id})
}
    const handleCaptionChange = (event) => {
        setPodcastCaption(event.target.value);
      };
    
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Check if the selected file is an MP3
        if (selectedFile && selectedFile.type === 'audio/mpeg') {
          setFile(selectedFile);
        } else {
          alert('Please select an MP3 file.');
        }
      };
    
    function onSubmit (){    
        console.log("notes file",file)
        const form = new FormData(); 

        form.append('podcast_caption',podcastCaption);    
        form.append('podcast_file',file); 
        form.append('type',type)
        UploadPodcast(form)
      
     }
     const togglePlay = () => {
        setIsPlaying(!isPlaying);
      };
    const PodcastMeditationScreen=()=>{
        return(
          <>
           <ToastContainer />
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div>         
          <TextBox             
             label={"Podcast Caption"}
             id={"podcastCaption"}
             name={"podcastCaption"}
             autoFocus={true}                         
            value={podcastCaption}  
            isSelect={false}      
            onChange={handleCaptionChange}
            />        
        </div>
        <div>
         
          <input
            type="file"
            accept="audio/*"
            onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />
        </div>
      
       
            <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allPodcasts}
                  />
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {PodcastMeditationScreen()}
   </AdminTemplate>
  )
}

export default PodcastMeditation