export default build =>
    build.mutation({
        query(payload) {
            console.log("payload ind eleye podcats mobile",payload)
            return {
                url: '/podcast/deletePodcast',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['allUploadedPodcasts'],
    })

