import React,{useState,useEffect} from 'react'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { Grid, Paper,Container, Button } from '@mui/material'
import AddRecordedVideos from '../Components/AddRecordedVideos';
import { useGetCourseByIdQuery } from '../Services/modules/course';
import { useSelector } from 'react-redux'
import AddRecordedLiveSessions from '../Components/AddRecordedLiveSessions';
import AddCourseThumbnailImage from '../Components/AddCourseThumbnailImage';
import AddCourseIntroVideo from '../Components/AddCourseIntroVideo';
import AddCourseFaqs from '../Components/AddCourseFaqs';

function CourseContent() {
  const course_id = useSelector(state => state.courseData.course_data._id);
//console.log("course_id",course_id)
    const [expanded, setExpanded] = React.useState(false);
   const[courseDataLoading,setCourseDataLoading]=useState(true)
    const { 
      data: courses= [],  
      isLoading:loading=true,
      isSuccess:getAllCoursesSuccess=false,
      refetch 
     } = useGetCourseByIdQuery({course_id:course_id});

     //console.log("courses.CourseData[0]",courses.CourseData[0])
     const handleRefetch = () => {
      refetch();
    };
     useEffect(() => {   
      if(getAllCoursesSuccess)
      {
//console.log("Courses",courses)
//console.log("Intro video link_parent",courses.CourseData[0].course_intro_video_link)
        // console.log("Check courses",courses)
        // course_section=courses.CourseData[0].course_recorded_videos_data
        // console.log("CourseSection",course_section)
        setCourseDataLoading(false)
        //console.log("Courses",courses.CourseData[0].course_recorded_videos_data)
        }
      
    }, [getAllCoursesSuccess]);

    const courseContentScreen=()=>{
        return (
           
          <div>
             {!courseDataLoading?<AddCourseIntroVideo
              course_id={course_id}
              intro_video_link={courses.CourseData[0].course_intro_video_link}
              refetch={handleRefetch}
                            
                           
                            />:""}
          {!courseDataLoading?<AddCourseThumbnailImage
                            thumbnail_image_link={courses.CourseData[0].course_thumbnail_image_link}
                            
                            course_id={course_id}
                            />:""}

                          {!courseDataLoading? <AddRecordedVideos 
                            course_section={courses.CourseData[0].course_recorded_videos_data} 
                            course_id={course_id}
                            refetch={handleRefetch}
                            />:""
                           
                            }
                             {/* {!courseDataLoading?<AddRecordedLiveSessions
                            course_recorded_live={courses.CourseData[0].recorded_live_session_data}
                            
                            course_id={course_id}
                            refetch={refetch}
                            />:""}
                             {!courseDataLoading?
                             <AddCourseFaqs                          
                            course_id={course_id}
                            allfaqs={courses.CourseData[0].course_faqs}
                            refetch={refetch}
                            />:""} */}
                           
                             
                            
     
     </div>
          )
    }
  return (
    
    <AdminTemplate>{courseContentScreen()}</AdminTemplate>
  )
}

export default CourseContent